import ApisMethods from './apisMethods'

const PackagesApi = {
  getPackageCategories: (callback: any, setIsLoading: any) => {
    //
    let url = `/vendor/packages/categories`

    ApisMethods().get(url, callback, setIsLoading)
  },
  getInfo: (callback: any, setIsLoading: any) => {
    //
    let url = `/vendor/subscriptions`

    ApisMethods().get(url, callback, setIsLoading)
  },
  getHistory: (
    searchText: string,
    rowsPerPage: number,
    page: number,
    callback: any,
    setIsLoading: any
  ) => {
    //
    let url = `/vendor/subscriptions/history??page=${page}&per_page=${rowsPerPage}`

    ApisMethods().get(url, callback, setIsLoading)
  },
  getHistoryById: (
    id: any,

    callback: any,
    setIsLoading: any
  ) => {
    //
    let url = `/vendor/subscriptions/details/${id}`

    ApisMethods().get(url, callback, setIsLoading)
  },
  getInvoById: (
    id: any,

    callback: any,
    setIsLoading: any
  ) => {
    //
    let url = `/vendor/subscriptions/invoice/${id}`

    ApisMethods().get(url, callback, setIsLoading)
  },
  getPackageCategoriesById: (id: any, callback: any, setIsLoading: any) => {
    //
    let url = `/vendor/packages/${id}`

    ApisMethods().get(url, callback, setIsLoading)
  },
  upgrade: (data: any, callback: any, setIsLoading: any, isCallbackErr?: any) => {
    ApisMethods(isCallbackErr).post(`/vendor/subscription/upgrade`, data, callback, setIsLoading)
  },
  upgradeConfirm: (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/subscription/upgrade/confirm`, data, callback, setIsLoading)
  },
  renew: (callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/subscription/renew`, callback, setIsLoading)
  },
  renewConfirm: (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/subscription/renew/confirm`, data, callback, setIsLoading)
  },
}
export default PackagesApi
