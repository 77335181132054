import {Checkbox, Stack} from '@mui/material'
import React, {useState} from 'react'
import Label from '../../customTable/Label'
import InputWithLabel from '../../customTable/LabelWithText'
import UploadImg from '../../UploadImg'
import CheckInputWithLabel from '../../customTable/CheckInputWithLabel'
import UploadImgBtn from '../../customTable/UploadImgBtn'
import StaticInputFile from '../../customTable/StaticInputFile'
import EmptyInput from '../../customTable/EmptyInput'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import MainBtn from '../../customTable/MainBtn'
import ValidationFun from '../../../yup/ValidationFun'
import {AppMakerSchema} from '../../../yup/AppMakerShcema'
import AppMaker from '../../../Api/AppMaker'
export default function IDoNotHaveAccountsInStoresForm({
  setIsSubmit,
  data,
  setData,
  android,
  active,
  setIsCompleteed,
  isCompleteed,
}: any) {
  const [base64Image, setBase64Image] = useState('')
  const store = useSelector((state: RootState) => state.vender)
  const [isLoading, setUsLoading] = React.useState(false)
  const [errors, setErrors] = useState<any>({})
  const appData = [
    'IdontHaveAccountfirstPoint',
    'IdontHaveAccountSecundPoint',
    'IdontHaveAccountTheardPoint',
    'IdontHaveAccountFoutPoint',
    'IdontHaveAccountFifthPoint',
  ]

  const nots = ['notesOne', 'notesTow', 'notesThree', 'notesFour', 'notesFive']
  return (
    <Stack gap='24px' p='25px' borderRadius={'8px'} bgcolor={'#fff'} mt='24px'>
      <Stack gap='40px'>
        <Stack
          border='1px solid var(--2, #D8D8D8)'
          borderRadius={'8px'}
          p='24px'
          bgcolor={'rgba(216, 25, 35, 0.10)'}
        >
          <Label fw='600' fs='16px' text='IdontHaveAccount' />
          <Label color='#545454' fs='10px' fw='300' text='IdontHaveAccountSubTitle' />
          <Stack mt='24px'>
            <Label fw='600' text='IdontHaveAccountTitlePoints' />
            <Stack gap='16px' mt='16px'>
              {appData.map((e: any) => (
                <Label fs='12px' text={e} />
              ))}
            </Stack>
          </Stack>
        </Stack>
        <Stack
          border='1px solid var(--2, #D8D8D8)'
          borderRadius={'8px'}
          p='24px'
          bgcolor={'rgba(80, 205, 137, 0.10)'}
        >
          <Label fw='600' fs='14px' text='ImportantNotes' />
          <Stack mt='16px'>
            <Stack gap='16px'>
              {nots.map((e: any) => (
                <Label fs='12px' text={e} />
              ))}
            </Stack>
          </Stack>
        </Stack>

        <InputWithLabel
          width={{md: '521px', xs: '100%'}}
          name={'working_email'}
          type='email'
          data={data}
          setData={setData}
          errors={errors}
          text='workMail'
        />
        <Stack bgcolor={'#C5C5D8'} height={'1px'} width={'100%'}></Stack>
        <Stack gap='32px'>
          <Label text={android ? 'androidApp' : 'isoApp'} />
          <Stack gap='15px'>
            <Label text='applicationIcon' />
            <UploadImg
              name='app_icon'
              data={data}
              setData={setData}
              base64Image={base64Image}
              setBase64Image={setBase64Image}
              errors={errors}
            />
          </Stack>
          <Stack flexWrap={'wrap'} gap='32px' direction={'row'}>
            <InputWithLabel
              width={{md: 'calc(50% - 16px)', xs: '100%'}}
              name={'account_mail'}
              type='email'
              data={data}
              errors={errors}
              setData={setData}
              text='appleIDAccount'
            />{' '}
            <InputWithLabel
              width={{md: 'calc(50% - 16px)', xs: '100%'}}
              name={'account_password'}
              data={data}
              type='password'
              setData={setData}
              errors={errors}
              text='password'
            />
          </Stack>
          <Stack bgcolor={'#C5C5D8'} height={'1px'} width={'100%'}></Stack>
        </Stack>
        <Stack width='100%' gap='24px' direction={'row'}>
          <CheckInputWithLabel
            onClick={() => setData({...data, check: 1})}
            check={data.check}
            value={1}
            text={'citizen'}
          />
          <CheckInputWithLabel
            onClick={() => setData({...data, check: 0})}
            check={data.check}
            value={0}
            text={'resident'}
          />
        </Stack>
        {data.check === 1 ? (
          <Stack flexWrap={'wrap'} gap='32px' direction={'row'}>
            <Stack gap='15px' position={'relative'} width={{xs: '100%', md: 'calc(50% - 16px)'}}>
              <Label text='frontImage' />

              <UploadImg
                name='id_face_one'
                data={data}
                setData={setData}
                errors={errors}
                base64Image={base64Image}
                setBase64Image={setBase64Image}
              />
            </Stack>
            <Stack gap='15px' position={'relative'} width={{xs: '100%', md: 'calc(50% - 16px)'}}>
              {/* {data.backImage ? (
                <StaticInputFile text='backImage' />
              ) : (
                <EmptyInput text='backImage' />
              )}
              <Stack
                sx={{
                  left: store.isRtl !== 0 ? '24px' : 'auto',
                  right: store.isRtl === 0 ? '24px' : 'auto',
                  bottom: '-43px',
                }}
                position={'absolute'}
              >
                {' '}
                <UploadImgBtn
                  base64Image={base64Image}
                  setBase64Image={setBase64Image}
                  name='backImage'
                  data={data}
                  setData={setData}
                />
              </Stack> */}
              <Label text='backImage' />

              <UploadImg
                name='id_face_two'
                data={data}
                errors={errors}
                setData={setData}
                base64Image={base64Image}
                setBase64Image={setBase64Image}
              />
            </Stack>
          </Stack>
        ) : (
          <>
            {' '}
            <Stack gap='15px' position={'relative'} width={{xs: '100%', md: 'calc(50% - 12px)'}}>
              <Label text='pasportImg' />

              <UploadImg
                name='passport'
                data={data}
                errors={errors}
                setData={setData}
                base64Image={base64Image}
                setBase64Image={setBase64Image}
              />
            </Stack>
          </>
        )}
        <Stack width={{xs: '100%', md: 'calc(50% - 12px)'}}>
          <UploadImgBtn
            name='commercial_file'
            note={'Only pdf , docx  files are accepted'}
            data={data}
            errors={errors}
            isPdf
            setData={setData}
            base64Image={base64Image}
            setBase64Image={setBase64Image}
            text='commercialRegister'
          />{' '}
        </Stack>
      </Stack>
      <Stack>
        <Stack alignContent={'center'} gap='3px' direction={'row'}>
          <Checkbox
            checked={data.is_agree}
            sx={{
              color: 'var(--main-color)',
              '&.Mui-checked': {
                color: 'var(--main-color)',
              },
            }}
          />
          <Stack mt='8px' alignContent={'center'} gap='5px' direction={'row'}>
            <Label fs='14px' text='iAgreeTo' />
            <Label fs='14px' color='#009EF7' text='theAppStorePublishingPolicy' />
          </Stack>
        </Stack>
        <Stack mt='41px' alignItems={'flex-end'}>
          <MainBtn
            width='auto'
            text='submit'
            isLoading={isLoading}
            handelSubmit={() => {
              ValidationFun(
                {
                  ...data,
                  check: data.check,
                  active: active === 2 ? 0 : 1,
                  is_agree: data.is_agree ? 1 : 0,
                },
                AppMakerSchema,
                setErrors,
                (value: any) => {
                  AppMaker.add(
                    {
                      ...data,
                      is_have_account: 0,
                      is_agree: data.is_agree ? 1 : 0,
                      app_type: android ? 'android' : 'ios',
                      vendor_type: data.check === 1 ? 'citizen' : 'resident',
                    },
                    () => {
                      setIsSubmit(true)
                      setIsCompleteed(!isCompleteed)
                    },
                    setUsLoading,
                    android
                  )
                }
              )
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}
