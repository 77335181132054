import * as yup from 'yup'
import {Language, languages} from '../../../types'

export interface InitialValues {
  status: boolean | number
  product_pictures?: any
  product_videos?: any
  sku: string
  unlimited_quantity: number
  quantity: number
  is_shipping_pickup: number
  //  id is_shipping_pickup is  true then shipping_pickup is required
  weight?: number | string
  height?: number | string
  length?: number | string
  width?: number | string
  cost?: number
  price: number
  discount_amount: number

  in_stock: number
  manufacturer_id: number | string

  value: string
  model: string
  image: string
  parent_id: {
    label: string
    value: string
  }
  sort: number

  name: {
    [key in Language]: string
  }
  description: {
    [key in Language]: string
  }
  permalink: {
    [key in Language]: string
  }
  meta_title: {
    [key in Language]: string
  }
  meta_description: {
    [key in Language]: string
  }
  meta_keywords: any

  product_tags: any
  is_featured: number
  related_products: Array<{
    label: string
    value: string
  }>
  categories: Array<{
    label: string
    value: string
  }>
  is_new_arrival: number
}

//   ValuesSchema  with error message for  multilanguage bas  of  in18n
export const validationSchema = yup.object().shape({
  status: yup.boolean().required('Status is required'),
  sku: yup.string().required('Sku is required'),
  unlimited_quantity: yup.number().required('Unlimited quantity is required'),
  // quantity: yup.number().required('Quantity is required'),
  is_shipping_pickup: yup.number().required('Is shipping pickup is required'),
  //  id is_shipping_pickup is  true then shipping_pickup is required
  weight: yup.number().when('is_shipping_pickup', {
    is: true,
    then: yup.number().required('Weight is required'),
  }),
  height: yup.number().when('is_shipping_pickup', {
    is: true,
    then: yup.number().required('Height is required'),
  }),
  length: yup.number().when('is_shipping_pickup', {
    is: true,
    then: yup.number().required('Length is required'),
  }),

  width: yup.number().when('is_shipping_pickup', {
    is: true,
    then: yup.number().required('Width is required'),
  }),
  // cost: yup.number().required('Cost is required'),
  price: yup.number().required('Price is required'),
  // discount_amount: yup.number().required('Selling price is required'),
  in_stock: yup.number().required('In stock is required'),
  manufacturer_id: yup.number().required('Manufacturer id is required'),
  value: yup.string().required('Value is required'),
  model: yup.string().required('Model is required'),
  image: yup.string().required('Image is required'),
  parent_id: yup.object().shape({
    label: yup.string().required('Parent id is required'),
    value: yup.string().required('Parent id is required'),
  }),
  sort: yup.number().required('Sort is required'),
  name: yup.object().shape(
    languages.reduce((acc, lang) => {
      return {...acc, [lang]: yup.string().required(` Name is required in ${lang}`)}
    }, {})
  ),
  permalink: yup.object().shape(
    languages.reduce((acc, lang) => {
      return {...acc, [lang]: yup.string().required(` Permalink is required in ${lang}`)}
    }, {})
  ),
  description: yup.object().shape(
    languages.reduce((acc, lang) => {
      return {...acc, [lang]: yup.string().required(` Description is required in ${lang}`)}
    }, {})
  ),
  meta_title: yup.object().shape(
    languages.reduce((acc, lang) => {
      return {...acc, [lang]: yup.string().required(` Meta title is required in ${lang}`)}
    }, {})
  ),
  meta_description: yup.object().shape(
    languages.reduce((acc, lang) => {
      return {...acc, [lang]: yup.string().required(` Meta description is required in ${lang}`)}
    }, {})
  ),
  meta_keywords: yup.object().shape(
    languages.reduce((acc, lang) => {
      return {...acc, [lang]: yup.string().required(` Meta keywords is required in ${lang}`)}
    }, {})
  ),
  product_tags: yup.object().shape(
    languages.reduce((acc, lang) => {
      return {...acc, [lang]: yup.string().required(` Product tags is required in ${lang}`)}
    }, {})
  ),
  is_featured: yup.number().required('Is featured is required'),
  related_products: yup.array().of(
    yup.object().shape({
      label: yup.string().required('Related products is required'),
      value: yup.string().required('Related products is required'),
    })
  ),
  // categories: yup
  //   .array()
  //   .min(1, 'Please select at least one category')
  //   .of(
  //     yup.object().shape({
  //       label: yup.string().required('Category label is required'),
  //       value: yup.string().required('Category value is required'),
  //     })
  //   ),
  is_new_arrival: yup.number().required('Is new arrival is required'),
})
