import React, {useCallback, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {Boundary} from '../../../componet/Bondary'
import {RootState, useDispatch, withToastr} from '../../../store'
import {FetchStatus} from '../../../store/types'
import {categoriesAction} from '../../../store/vender/catogory/thunk'
import {ArrangementOrder} from '../../../types'
import {getComparator, stableSort} from '../../../utils/sortTable'

import {PaginationSection} from '../../customTable/pagnationSection'
import {EnhancedTableHead} from './EnhancedTableHead'
import {RowItem} from './Row'
import {TooBar} from './TooBar'
import {CustomerListData} from './types'
import Catalog from '../../../Api/catalog'
import {CircularProgress, Stack} from '@mui/material'
import CatalogApi from '../../../Api/catalog'
import TableHead from './TablHead'
import NoDataTable from '../../customTable/NoDataTable'

const CategoryTable = () => {
  const [order, setOrder] = useState<ArrangementOrder>('asc')
  const [orderBy, setOrderBy] = useState<string>('name')
  const [selected, setSelected] = useState<string[]>([])
  const [page, setPage] = useState<number>(1)
  const [rowsPerPage, setRowsPerPage] = useState<number>(5)
  const [total, setTotal] = useState(1)
  const [rows, setRows] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const [search, setSearch] = useState<string>('')

  const [timerId, setTimerId] = useState<number | null>(null)

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const isSelected = (id: string) => selected.indexOf(id) !== -1
  const [num, setNum] = useState(0)
  const [numSub, setNumSub] = useState(0)
  useEffect(() => {
    if (total) {
      setNum(total)
    }
  }, [total])
  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newSearchValue = e.target.value
      setSearch(e.target.value)
      if (timerId) {
        clearTimeout(timerId)
      }

      setTimerId(
        window.setTimeout(() => {
          getCoupons(newSearchValue, 5, 1)
          setPage(1)
        }, 500)
      )
    },
    [timerId]
  )

  const getCoupons = (searchText: string, rowsPerPage: number, page: number) => {
    CatalogApi.getList(
      searchText,
      rowsPerPage,
      page,
      (coupons: any) => {
        setTotal(coupons.pages.total)
        setNumSub(coupons.sub_categories_count)
        setRows(coupons.data)
      },
      setIsLoading
    )
  }

  const handleChangeRowsPerPage = (newPrePage: number) => {
    setRowsPerPage(newPrePage)
    setPage(1)
  }
  useEffect(() => {
    getCoupons('', rowsPerPage, page)
  }, [rowsPerPage, page])
  return (
    <Stack mt='31px' className={`card `}>
      <TooBar numSub={numSub} num={num} handleSearch={handleSearch} search={search} />
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <TableHead />

            {/*  id  status is pending  will  show  spinner  */}

            {/* help-Cata-list */}
            <tbody>
              {
                isLoading ? (
                  <CircularProgress />
                ) : rows.length === 0 ? (
                  <NoDataTable />
                ) : (
                  rows.map((row: any, index: any) => {
                    if (typeof row === 'number') return null
                    const isItemSelected = isSelected(row?.id || '')

                    return (
                      <RowItem
                        id={row.id}
                        i={index}
                        name={row.name}
                        status={row.status}
                        appear_on_home={row.appear_on_home}
                        image={(row.image as string) || ''}
                        description={(row.description! as string) || ' not found'}
                        key={row?.id}
                        setNum={setNum}
                        setRows={setRows}
                      />
                    )
                  })
                )
                // : (
                //   <tr>
                //     <td colSpan={7}>
                //       <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                //         No matching records found
                //       </div>
                //     </td>
                //   </tr>
                // )
              }
            </tbody>
          </table>
          {/*  WILL  SHOW  PAGNATION  SECTION  ONLY  IF  THERE  IS  DATA */}

          {/*  If  ther  any  error  will  show   */}
        </div>
        <PaginationSection
          rowsPerPageOptions={[5, 20, 30, 40, 50, 60]}
          count={total || 1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </Stack>
  )
}

export default CategoryTable
