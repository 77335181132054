import {Stack} from '@mui/material'
import React from 'react'
import Label from '../customTable/Label'
import LabelWithValue from './LabelWithValue'

export default function ShowPlan({data}: any) {
  return (
    <Stack gap='24px' p='32px'>
      <Label fw='600' text='planFeatures' />
      <Stack p='0px 0 20px 0' width={'100%'} borderBottom={'1px solid #d5d2d2'}>
        <LabelWithValue gap='30px' label='SR.NO' value='feature' />
      </Stack>
      <Stack gap='24px'>
        {data &&
          data.map((e: any, i: any) => <LabelWithValue label={i + 1} value={e.feature_name} />)}
      </Stack>
    </Stack>
  )
}
