import {Stack} from '@mui/material'
import React, {useState, useEffect} from 'react'
import CardsImage from './CardsImage'
import Contant from './Contant'
import * as Yup from 'yup'

import RestSaveButton from '../../customTable/RestSaveBtns'
import GeneralApi from '../../../Api/SettingGeneral'
import {GeneralSettingValidation} from '../../../yup/SettingSchema'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import {actions} from '../../../store/vender/Auth/auth.slice'
import {toast} from 'react-toastify'
import ErrorMsg from '../../customTable/ErrorMsg'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import ValidationFun from '../../../yup/ValidationFun'
import Loading from '../../customTable/LoadingForm'
export default function General() {
  const {t} = useTranslation()

  const [isLoading, setIsLoading] = useState(false)
  const [old, setOld] = useState({})
  const [setting, setSeeting] = useState<any>({
    header_logo: '',
    footer_logo: '',
    app_logo: '',
    identity_papers: '',
    title: {},
    mobile: '',
    description: {},
    color: '#3758b5',
  })

  const [msgErr, setMsgErr] = React.useState<string>('')
  const [isLoadingGeneral, setIsLoadingGeneral] = useState(false)

  const getSetting = () => {
    GeneralApi.get((res: any) => {
      setSeeting({
        ...res.data,
        color: res.data.color ? res.data.color : '#0C68F4',
        description: Array.isArray(res.data.description) ? {} : res.data.description,
      })

      setOld({
        ...res.data,
        color: res.data.color ? res.data.color : '#0C68F4',
        description: Array.isArray(res.data.description) ? {} : res.data.description,
      })
    }, setIsLoadingGeneral)
  }
  useEffect(() => {
    getSetting()
  }, [])
  const dispatch = useDispatch()
  const [languages, setlanguages] = useState(['en'])
  const store = useSelector((state: RootState) => state.vender)

  useEffect(() => {
    setlanguages(store.storeLangs)
  }, [store.storeLangs])
  const [errors, setErrors] = useState({})
  const handelSubmit = async () => {
    console.log(setting.app_logo, 'setting')

    ValidationFun(setting, GeneralSettingValidation(languages), setErrors, () => {
      if (setting.header_logo && !setting.header_logo.includes('data:image')) {
        delete setting.header_logo
      }
      if (setting.favicon && !setting.favicon.includes('data:image')) {
        delete setting.favicon
      }
      if (setting.footer_logo && !setting.footer_logo.includes('data:image')) {
        delete setting.footer_logo
      }
      if (setting.app_logo && !setting.app_logo.includes('data:image')) {
        delete setting.app_logo
      }
      if (setting.identity_papers && !setting.identity_papers.includes('base64')) {
        delete setting.identity_papers
      }

      GeneralApi.put(
        {...setting},
        (res: any) => {
          localStorage.setItem('storeImage', res.data.header_logo)
          dispatch(actions.getStoreImage(res.data.header_logo))

          setSeeting(res.data)
          setOld(res.data)
          setMsgErr('')
        },
        setIsLoading
      )
    })
  }
  console.log(setting, 'setting')

  return (
    <Stack minHeight={'60vh'} position={'relative'}>
      {isLoadingGeneral ? (
        <Loading />
      ) : (
        <Stack>
          <CardsImage setSeeting={setSeeting} setting={setting} />
          <Contant errors={errors} msgErr={msgErr} setSeeting={setSeeting} setting={setting} />
          <Stack>
            {store.permissions && store.permissions.includes('general_update') && (
              <RestSaveButton
                isLoading={isLoading}
                SaveText={t('save')}
                cancelText={t('rest')}
                submit={handelSubmit}
                errors={errors}
                cancel={() => {
                  setSeeting(old)
                }}
              />
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}
