import React, {useState} from 'react'
import {ActionsCell} from '../../componet/Menu'
import {Avatar, Box, Stack} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import Manufacturers from '../../Api/manufacturers'
import {RootState} from '../../store'
import {useSelector} from 'react-redux'
import SimpleLoading from '../customTable/SimpleLoading'
import {Link, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import VariationRow from './VariationRow'
import ViewBtn from '../category/addProduct/ViewBtn'
import Product from '../../Api/product'
import PupUpImage from '../customTable/PupUpImage'
import NameText from '../customTable/NameText'
import FixedNumber from '../customTable/FixedNumber'
import Image from '../customTable/Image'
import Label from '../customTable/Label'

export default function Row({
  item,
  i,
  setRows,
  rows,
  setIsLoading,
  setNum,
  setAdded,
}: {
  item: any
  i: number
  setRows: any
  rows: any
  setIsLoading: any
  setNum: any
  setAdded: any
}) {
  const [state, setState] = useState<any>(item.status)
  const [open, setOpen] = React.useState(false)
  const [editItem, setEditItem] = useState(item)
  const handelEdit = () => {
    setOpen(true)
  }
  const [isLoadingStatus, setIsLoadingStatus] = useState(false)
  const handleStatusChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    Manufacturers.putStatus(
      item.id,
      {status: state === 1 ? 0 : 1},
      () => {
        setRows(
          [
            ...rows.filter((e: any) => e.id != item.id),
            {...editItem, status: state === 1 ? 0 : 1},
          ].sort((a, b) => a.id - b.id)
        )
        setState(state === 1 ? 0 : 1)
      },
      setIsLoadingStatus
    )
  }
  const [isDeleted, setDeleted] = useState(true)
  const [isLoadingDelete, setIsLoadingDelete] = useState(false)
  const {t} = useTranslation()

  const handelDelete = async () => {
    Product.destroy(
      item.id,
      () => {
        setRows((prev: any) => prev.filter((e: any) => e.id !== item.id))
        setIsLoading(!true)
        setAdded(true)

        setNum((prev: any) => prev - 1)
      },
      setIsLoadingDelete
    )

    // setDeleted(false)
  }
  const {lang, defLang} = useSelector((state: RootState) => state.vender)
  const store = useSelector((state: RootState) => state.vender)
  const [isDrow, setIsDrow] = useState(false)
  const [openView, setView] = useState(false)

  const navigate = useNavigate()

  const {
    id,
    variation_values,
    image,
    name,
    rate,
    price,
    unlimited_quantity,
    quantity,
    sku,
    in_stock,
    status,
  } = item
  const handleEdit = () => {
    navigate(`/catalog/edit-product/${id}`)
  }
  return (
    <>
      {isDeleted && (
        <>
          <tr>
            <td className=''>
              <span className='fw-bold'>{i + 1}</span>
            </td>
            <td
              onClick={() => {
                if (variation_values && variation_values.length > 0) {
                  setIsDrow(!isDrow)
                }
              }}
            >
              <div className='d-flex align-items-center'>
                <div>
                  <Link
                    style={{display: 'flex', gap: '10px', alignItems: 'center'}}
                    to=''
                    className='text-gray-800 text-hover-primary fs-5 fw-bold'
                  >
                    {' '}
                    <PupUpImage image={image}>
                      <Image
                        sx={{width: '50px', height: '50px', borderRadius: '8px'}}
                        alt={
                          name &&
                          (name[lang] || name[defLang]) &&
                          (name[lang] || name[defLang]).slice(0, 15)
                        }
                        src={editItem.image}
                      />{' '}
                    </PupUpImage>
                    <NameText text={name && (name[lang] || name[defLang])} />
                    <Box sx={{height: '38px'}}>
                      {variation_values && variation_values.length > 0 && (
                        <KeyboardArrowDownIcon sx={{transform: isDrow ? 'rotateX(180deg)' : ''}} />
                      )}
                    </Box>
                  </Link>
                </div>
              </div>
            </td>

            <td className=''>
              <span className='fw-bold'>{sku}</span>
            </td>
            <td data-order='17'>
              <>
                {unlimited_quantity === 1 && in_stock === 1 ? (
                  <span className='fw-bold ms-3'>{t('unlimited')}</span>
                ) : (
                  <>
                    {' '}
                    {quantity !== 0 && quantity <= 10 && in_stock === 1 && (
                      <span className='badge badge-light-warning'>{t('lowStock')}</span>
                    )}
                    {in_stock === 0 && (
                      <span
                        style={{
                          color: 'red',
                          background: '#ff000040',
                          padding: '2px 5px',
                          borderRadius: '4px',
                        }}
                      >
                        {t('outOfStock')}
                      </span>
                    )}
                    {in_stock === 1 && <span className='fw-bold ms-3'>{quantity}</span>}
                  </>
                )}
              </>
            </td>
            <td>
              {' '}
              <FixedNumber num={price} />
            </td>
            <td>
              <div className='rating '>
                {Array.from({length: rate}, (_, i) => (
                  <div className='rating-label checked'>
                    <span className='svg-icon svg-icon-2'>
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z'
                          fill='currentColor'
                        />
                      </svg>
                    </span>
                  </div>
                ))}
                {Array.from({length: 5 - rate}, (_, i) => (
                  <div className='rating-label'>
                    <span className='svg-icon svg-icon-2'>
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z'
                          fill='currentColor'
                        />
                      </svg>
                    </span>
                  </div>
                ))}
              </div>
            </td>
            <td className=' pe-0' data-order='Published'>
              <div
                style={{
                  padding: '7px 16px',
                  color: status === 0 ? 'red' : 'green',
                  background: status === 0 ? '#ff00003d' : '#0bed0b52',
                }}
                className='badge '
              >
                {t(status === 0 ? 'notActive' : 'active')}
              </div>
            </td>

            <td className='text-center'>
              {isLoadingDelete ? (
                <SimpleLoading />
              ) : (
                <ActionsCell
                  onEdit={
                    store.permissions && store.permissions.includes('products_update') && handleEdit
                  }
                  onDelete={
                    store.permissions &&
                    store.permissions.includes('products_delete') &&
                    handelDelete
                  }
                  onView={() => {
                    setView(true)
                  }}
                  onReview={() => {
                    navigate(`/catalog/products/review/${id}`)
                  }}
                />
              )}
            </td>
          </tr>
          <tr style={{borderBottom: 'none'}}>
            <td colSpan={7}>
              <Stack
                width={'100%'}
                sx={{transition: '0.25s linear all'}}
                maxHeight={isDrow ? '350px' : '0px'}
                // bgcolor={'red'}
                gap='12px'
              >
                {variation_values.map((e: any, i: any) => (
                  <Stack key={i} sx={{display: isDrow ? 'block' : 'none'}}>
                    <VariationRow item={e} />
                  </Stack>
                ))}
              </Stack>
            </td>
          </tr>
          <ViewBtn id={id} setOpen={setView} open={openView} />
        </>
      )}
    </>
  )
}
