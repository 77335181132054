import {Stack} from '@mui/material'
import React, {useState} from 'react'
import Label from '../../../customTable/Label'
import {useTranslation} from 'react-i18next'
import MainBtn from '../../../customTable/MainBtn'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../store'
import Product from '../../../../Api/product'
import {useParams} from 'react-router-dom'
import Link from './Link'
import {actionsProducts} from '../../../../store/vender/products/slice'
import {useDispatch} from 'react-redux'

export default function Videos({formik}: any) {
  const {product} = useSelector((state: RootState) => state.product)
  const dispatch = useDispatch()

  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)
  const [isLoading, setIsLoading] = useState(false)
  const {id} = useParams()
  const [val, setVal] = useState('')
  const add = () => {
    if (!isLoading) {
      Product.storeVideo(
        {product_id: id, video: val},
        (res: any) => {
          setVal('')
          if (product && product.product_pictures) {
            dispatch(
              actionsProducts.setProduct({
                ...product,
                product_pictures: [...product.product_pictures, res] || [],
              })
            )
          } else {
            dispatch(
              actionsProducts.setProduct({
                ...product,
                product_pictures: [res],
              })
            )
          }
        },
        setIsLoading
      )
    }
  }
  return (
    <Stack minHeight={'300px'} gap='8px' p='0 29.25px'>
      <Label text='addVideo' />
      <Stack position={'relative'}>
        <input
          value={val}
          onChange={(e: any) => setVal(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              add()
            }
          }}
          placeholder={`${t('videoLink')}`}
          style={{
            outline: 'none',
            border: '1px solid #D8D8D8',
            borderRadius: '8px',
            padding: '16px',
          }}
        />
        <Stack
          top='8px'
          right={store.isRtl === 0 ? '10px' : 'auto'}
          left={store.isRtl !== 0 ? '10px' : 'auto'}
          position={'absolute'}
        >
          <MainBtn
            disabled={val == '' ? true : false}
            isLoading={isLoading}
            handelSubmit={() => {
              add()
            }}
            color='#405189'
            bgcolor='#4051891A'
            width='fit-content'
            text='submitVideo'
          />
        </Stack>
      </Stack>
      <Stack gap='16px' mt='16px'>
        {product.product_pictures &&
          product.product_pictures
            .filter((e: any) => e.media_type === 'video')
            .map((e: any) => <Link link={e} />)}
      </Stack>
    </Stack>
  )
}
