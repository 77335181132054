import ErrorStatus from '../modules/customTable/ErrorStatus'

interface Props {
  error: null | string
  isLoading: any
  children: React.ReactNode
  errorStatus?: any
}

//   build Loading component spinner will be  center of screen and will be show when isLoading is true
export function Loading() {
  return (
    <div className='d-flex justify-content-center'>
      <div className='spinner-border' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </div>
    </div>
  )
}
export function Boundary(props: Props) {
  const {error, isLoading, children, errorStatus} = props
  if (errorStatus) {
    return <ErrorStatus status={errorStatus} />
  }
  if (error) {
    return <p>errror</p>
  }
  if (isLoading) {
    return <Loading />
  }
  return <>{children}</>
}
