import ApisMethods from './apisMethods'

const Catalog = {
  get: async (callback: any, setIsLoading?: any) => {
    ApisMethods().get(`/vendor/categories/get/names`, callback, setIsLoading)
  },
  getById: (id: any, callback: any, setIsLoading?: any) => {
    ApisMethods().get(`/vendor/categories/${id}`, callback, setIsLoading)
  },
  getList: async (
    searchText: string,
    rowsPerPage: number,
    page: number,
    callBack: any,
    setIsLoading: any
  ) => {
    ApisMethods().get(
      `/vendor/categories?page=${page}&per_page=${rowsPerPage}&search=${searchText}`,
      callBack,
      setIsLoading
    )
  },
  getListSubCategory: async (
    id: any,
    searchText: string,
    rowsPerPage: number,
    page: number,
    callBack: any,
    setIsLoading: any
  ) => {
    ApisMethods().get(
      `/vendor/categories/${id}/subcategories?page=${page}&per_page=${rowsPerPage}&search=${searchText}`,
      callBack,
      setIsLoading
    )
  },
  add: (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/categories`, data, callback, setIsLoading)
    // return Apiservices.post(`/vendor/banners`, data)
  },
  put: (id: any, data: any, callback: any, setIsLoading: any) => {
    ApisMethods().put(`/vendor/categories/${id}`, data, callback, setIsLoading)
  },
  putAppearOnHome: (id: any, data: any, callback: any, setIsLoading: any) => {
    ApisMethods().put(`/vendor/categories/appear_on_home/${id}`, data, callback, setIsLoading)
  },
  delete: (id: number | undefined, callback: any, setIsLoading: any) => {
    ApisMethods().destroy(`/vendor/categories/${id}`, callback, setIsLoading)
  },
}
export default Catalog
