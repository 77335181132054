import {useEffect, useState} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {reInitMenu} from '../helpers'
import {
  InviteUsers,
  ThemeModeProvider,
  // UpgradePlan
} from '../partials'
import {Content} from './components/content'
import {FooterWrapper} from './components/footer'
import {HeaderWrapper} from './components/header'
import {ScrollTop} from './components/scroll-top'
import {Sidebar} from './components/sidebar'
import {ToolbarWrapper} from './components/toolbar'
import {PageDataProvider} from './core'
import {Box, Stack} from '@mui/material'
import {SidebarLogo} from './components/sidebar/SidebarLogo'
import {SidebarMenu} from './components/sidebar/sidebar-menu/SidebarMenu'
import {useSelector} from 'react-redux'
import {RootState} from '../../app/store'
import SidBarItems from './SidBarItems'
import DrawerSideMenu from './DrowerSideBar'
import Label from '../../app/modules/customTable/Label'

const MasterLayout = () => {
  const location = useLocation()
  useEffect(() => {
    reInitMenu()
  }, [location.key])
  const screenWidth = window.innerWidth
  const {sizeImages} = useSelector((state: RootState) => state.vender)
  const [activeMenu, setActiveMenu] = useState(true)

  return (
    //     <RequireVendOrAuth>
    <PageDataProvider>
      <ThemeModeProvider>
        <Stack
          // style={{minHeight: '100vh'}}
          className='d-flex flex-column flex-root app-root'
          id='kt_app_root'
          alignItems={'center'}
        >
          <Stack p={{xs: '0 10px', md: '0 30px'}} width='100%' maxWidth={'1440px'}>
            <Stack width='100%' className='app-page flex-column flex-column-fluid' id='kt_app_page'>
              <HeaderWrapper activeMenu={activeMenu} />
              <Stack
                sx={{
                  position: 'fixed',
                  top: '142px',
                  // width: '321px',
                  height: '0',
                  // bgcolor: 'red',
                }}
                display={{xs: 'none', md: 'block'}}
              >
                <SidBarItems activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
                <Stack height={'500px'}></Stack>
              </Stack>

              <Stack
                mt={{md: '24px', xs: '20px'}}
                className=' flex-column flex-row-fluid'
                id='kt_app_wrapper'
              >
                <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                  <div
                    // style={{marginTop: '123px'}}
                    className='d-flex flex-column flex-column-fluid'
                  >
                    <ToolbarWrapper />
                    <Stack minHeight={'100vh'} justifyContent={'space-between'}>
                      <Stack
                        // gap={{md: activeMenu ? '50px' : '32px'}}
                        direction={'row'}
                      >
                        <Stack height={'60vh'} width={{md: activeMenu ? '288px' : '143px'}}></Stack>
                        <Stack
                          minHeight={'60vh'}
                          width={{
                            xs: '100%',
                            md: activeMenu ? 'calc(100% - 288px)' : 'calc(100% - 143px)',
                          }}
                        >
                          {sizeImages.pay && !sizeImages.pay.is_link_pay && (
                            <Stack mb='20px' bgcolor={'#FBE8E9'} p='20px'>
                              <Label
                                fs='17'
                                fw='600'
                                align='center'
                                color='#405189'
                                text={'PurchasesInYour'}
                              />
                            </Stack>
                          )}

                          <Outlet />
                        </Stack>
                      </Stack>
                      <Stack mt='32px'></Stack>
                      {/* <FooterWrapper /> */}
                    </Stack>
                  </div>
                </div>
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        {/* begin:: Drawers */}
        {/* <ActivityDrawer />
        <RightToolbar />
        <DrawerMessenger /> */}
        {/* end:: Drawers */}

        {/* begin:: Modals */}
        <InviteUsers />
        {/* <UpgradePlan /> */}
        {/* end:: Modals */}
        <ScrollTop />
      </ThemeModeProvider>
    </PageDataProvider>
    //     </RequireVendOrAuth>
  )
}

export {MasterLayout}
