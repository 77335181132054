import {IPageCatogoriesWithID} from './types.d'
import {createAsyncThunk} from '@reduxjs/toolkit'
import store from '../..'
import API from '../../../Api'
import {thunkCatchError} from '../../utils'
import {IGetCatogoryDetails, IPageCatogories} from './types'

const CATOGORY = {
  GET_CATAGORIES: '/vendor/categories',
  //    NAME
  GET_CATAGORIES_NAME: '/vendor/categories/get/names',
  CREATE_SUB_CATOGERIES: '/vendor/categories',

  //    by_id
  GET_CATAGORIES_BY_ID: '/vendor/categories/edit',
  GET_SUB_CATOGERIES_BY_ID: '/vendor/subcategories',
}

export const categoriesAction = createAsyncThunk(
  'category/categories',
  thunkCatchError(async ({page = 1, pre_page = 2, search}: IPageCatogories) => {
    let query = `?page=${page}&per_page=${pre_page}`
    if (search) {
      query += `&search=${search}`
    }

    const URL = `${CATOGORY.GET_CATAGORIES}${query}`

    const response = await API.get(URL)

    return (
      {
        data: response?.data?.data,
        pages: response?.data?.pages,
      } || {}
    )
  })
)
export const categoryDetailsAction = createAsyncThunk(
  'category/categories',
  thunkCatchError(async ({page = 1, pre_page = 15, id}: IGetCatogoryDetails) => {
    const response = await API.get(
      `${CATOGORY.GET_CATAGORIES}/${id}?page=${page}&per_page=${pre_page}`
    )
    return (
      {
        data: response.data?.data,
        pages: response.data?.pages,
      } || {}
    )
  })
)

export const deleteCategoryAction = createAsyncThunk(
  'category/deleteCategory',
  thunkCatchError(async (id: string) => {
    await API.delete(`${CATOGORY.GET_CATAGORIES}/${id}`)
    //    we  need  to delete  data  form  store  after  delete  from  server
    const {data, pages} = store.getState()?.category?.categories

    const newData = data?.filter((item: any) => {
      return item.id !== id
    })

    return {
      data: newData,
      pages,
    }
  })
)

//  we need  to  conver  file image to  base64  and  send  to  server

function convertFileToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const base64String = reader.result?.toString().split(',')[1]

      if (base64String) {
        resolve(base64String)
      } else {
        reject('Error converting file to Base64')
      }
    }
    reader.onerror = () => {
      reject('Error reading file')
    }
  })
}

//   create  one  Catogory
export const createCategoryAction = createAsyncThunk(
  'category/createCategory',
  thunkCatchError(async (inputData: any) => {
    //   need to  conver  image  to  base
    try {
      const response = await API.post(CATOGORY.GET_CATAGORIES, {
        ...inputData,
        status: !inputData.status ? 0 : inputData.status,
      })
      return response.data?.data || {}
    } catch (err: any) {
      return err.response.data.message
    }
  })
)

//   get catogoryName
export const getCategoryName = createAsyncThunk(
  'category/getCategoryName',
  thunkCatchError(async () => {
    const response = await API.get(`${CATOGORY.GET_CATAGORIES_NAME}`)
    return response.data?.data || {}
  })
)

//   we  need  to  get  catofory by id
export const getCategoryById = createAsyncThunk(
  'category/getCategoryById',
  thunkCatchError(async (id: string) => {
    try {
      const response = await API.get(`${CATOGORY.GET_CATAGORIES_BY_ID}/${id}`)
      return response.data?.data || {}
    } catch (err: any) {
      return {errorStatus: err.response.status}
      console.log(err.response, 'errd')
    }
  })
)

export const getSubCategoryById = createAsyncThunk(
  'category/getSubCategoryById',
  thunkCatchError(async (id: string) => {
    const response = await API.get(`${CATOGORY.GET_SUB_CATOGERIES_BY_ID}/${id}`)
    return response.data?.data || {}
  })
)

//   IS  IMAGE  BASE46
export const isBase64 = (str: string) => {
  return str.startsWith('data:image/png;base64,') ? true : false
}

//   update one  Catogory
export const updateCategoryAction = createAsyncThunk(
  'category/updateCategory',
  thunkCatchError(async (inputData: any) => {
    const isImageBase64 = inputData.image.includes('data')
    // isBase64(inputData.image)

    const image = inputData.image
    if (!isImageBase64) {
      delete inputData.image
    }
    try {
      const resposnive = await API.put(`${CATOGORY.GET_CATAGORIES}/${inputData.id}`, {
        ...inputData,
        status: !inputData.status ? 0 : inputData.status,
      })

      const catogorise = store.getState()?.category?.categories
      if (catogorise?.data?.length === 0) {
        store.dispatch(categoriesAction({page: 1, pre_page: 10}))
      }

      const newCatogories = catogorise?.data?.map((item: any) => {
        if (item.id == inputData.id) {
          if (!isImageBase64) {
            return {
              ...resposnive.data?.data?.id,
              ...inputData,
              image: image,
            }
          } else {
            return {
              ...resposnive.data?.data?.id,
              ...inputData,
            }
          }
        }
        return item
      })

      return newCatogories
    } catch (err: any) {
      if (err.response.status === 500) {
        return 500
      } else {
        return err.response.data.message
      }
    }
  })
)
export const updateSubCategoryAction = createAsyncThunk(
  'category/updateSubCategoryAction',
  thunkCatchError(async (inputData: any) => {
    try {
      const resposnive = await API.put(`${CATOGORY.CREATE_SUB_CATOGERIES}/${inputData.id}`, {
        ...inputData,
        status: !inputData.status ? 0 : inputData.status,
      })

      return resposnive.data?.data
    } catch (err: any) {
      return err.response.data.message
    }
  })
)

//   get CatogrorySubWith  data

export const getCategoryWithName = createAsyncThunk(
  'category/getCategoryWithName',
  thunkCatchError(async (id: string) => {
    const resposne = await API.get(`${CATOGORY.GET_CATAGORIES_NAME}/${id}`)

    return resposne.data?.data || {}
  })
)
export const getCategoryWithNameAndSubCatogory = createAsyncThunk(
  'category/getCategoryWithNameAndSubCatogory',
  thunkCatchError(async ({page = 1, pre_page = 10, search}: IPageCatogories) => {
    let query = `?page=${page}&per_page=${pre_page}`
    if (search) {
      query += `&search=${search}`
    }
    const URL = `${CATOGORY.GET_SUB_CATOGERIES_BY_ID}${query}`

    const resposne = await API.get(`${CATOGORY.GET_SUB_CATOGERIES_BY_ID}`)

    return resposne.data?.data || {}
  })
)

//   create  subCatogr   one  Catogory
export const createSubCategoryAction = createAsyncThunk(
  'category/createSubCategoryAction',
  thunkCatchError(async (inputData: any) => {
    try {
      const res = await API.post(CATOGORY.CREATE_SUB_CATOGERIES, {
        ...inputData,
        status: !inputData.status ? 0 : inputData.status,
      })

      return res.data?.data || {}
    } catch (err: any) {
      return err.response.data.message
    }
  })
)

//  getsUBcATOGORYlIST
export const getSubCategoryList = createAsyncThunk(
  'category/getSubCategoryList',
  thunkCatchError(async ({page = 1, pre_page = 10, search, id}: IPageCatogoriesWithID) => {
    let query = `?page=${page}&per_page=${pre_page}`
    if (search) {
      query += `&search=${search}`
    }
    const URL = `${CATOGORY.GET_CATAGORIES}/${id}${query}`

    const response = await API.get(URL)

    return response.data || {}
  })
)
