import React from 'react'

export default function Percentage() {
  return (
    <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.65926 10.4405L1.64594 9.42711C1.2326 9.01377 1.2326 8.33377 1.64594 7.92043L2.65926 6.90709C2.8326 6.73375 2.9726 6.39375 2.9726 6.15375V4.7204C2.9726 4.13373 3.4526 3.65375 4.03927 3.65375H5.4726C5.7126 3.65375 6.0526 3.51377 6.22593 3.34044L7.23926 2.32709C7.65259 1.91376 8.3326 1.91376 8.74593 2.32709L9.75928 3.34044C9.93261 3.51377 10.2726 3.65375 10.5126 3.65375H11.9459C12.5326 3.65375 13.0126 4.13373 13.0126 4.7204V6.15375C13.0126 6.39375 13.1526 6.73375 13.3259 6.90709L14.3393 7.92043C14.7526 8.33377 14.7526 9.01377 14.3393 9.42711L13.3259 10.4405C13.1526 10.6138 13.0126 10.9538 13.0126 11.1938V12.6271C13.0126 13.2137 12.5326 13.6938 11.9459 13.6938H10.5126C10.2726 13.6938 9.93261 13.8338 9.75928 14.0071L8.74593 15.0204C8.3326 15.4338 7.65259 15.4338 7.23926 15.0204L6.22593 14.0071C6.0526 13.8338 5.7126 13.6938 5.4726 13.6938H4.03927C3.4526 13.6938 2.9726 13.2137 2.9726 12.6271V11.1938C2.9726 10.9471 2.8326 10.6071 2.65926 10.4405Z'
        stroke='#F44336'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M6 10.667L10 6.66699'
        stroke='#F44336'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M9.66561 10.3338H9.6716'
        stroke='#F44336'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M6.32967 7.00033H6.33566'
        stroke='#F44336'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
