import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import {Stack} from '@mui/material'

export default function TableHead({data, isMostPopular}: any) {
  const {t} = useTranslation()

  const headTable = [
    {
      name: t(''),
      class: 'min-w-150px',
    },
    {
      name: t('free'),
      class: 'min-w-150px',
    },
    {
      name: t('standard'),
      class: 'min-w-150px',
    },
    {
      name: t('premium'),
      class: 'min-w-150px',
    },
    {
      name: t('ultimate'),
      class: 'min-w-150px',
    },
  ]
  const {lang, defLang} = useSelector((state: RootState) => state.vender)

  return (
    <thead>
      <tr
        // style={{display:'flex',justifyContent:'space-between'}}
        className='fw-bold text-muted'
      >
        {' '}
        <th
          className='min-w-150px'
          style={{
            textAlign: 'center',
          }}
        ></th>
        {data.map((e: any, i: any) => {
          return (
            <>
              <th
                className='min-w-200px'
                style={{
                  textAlign: 'center',
                  width: 'auto',
                }}
                key={i}
              >
                <Stack
                  p='8px'
                  borderRadius={'0px 0px 4px 4px'}
                  margin={'0 auto 13px auto'}
                  width={'90%'}
                  color='#fff'
                  bgcolor={isMostPopular[i] === 1 ? '#FFA800' : '#fff'}
                  maxWidth={'166px'}
                >
                  {isMostPopular[i] === 1 && t('mostPopular')}
                </Stack>

                {e && e.name && (e.name[lang] || e.name[defLang])}
              </th>
            </>
          )
        })}
      </tr>
    </thead>
  )
}
