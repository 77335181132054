import {Box, Stack} from '@mui/material'
import React, {useEffect, useState} from 'react'
import TitlePage from '../customTable/TitlePage'
import {useTranslation} from 'react-i18next'
import Label from '../customTable/Label'
import PupUpImage from '../customTable/PupUpImage'
import Image from '../customTable/Image'
import MainBtn from '../customTable/MainBtn'
import {useNavigate} from 'react-router-dom'
import Tabs from './Tabs'
import TemplateCard from './TemplateCard'
import Temps from '../../Api/Temps'
import SimpleLoading from '../customTable/SimpleLoading'
import Loading from '../customTable/LoadingForm'
import NoDataTable from '../customTable/NoDataTable'

export default function TemplatesList() {
  const {t} = useTranslation()
  const [categories, setCategories] = useState([])
  const [isLoadingCategories, setIsLoadingCategories] = useState(true)
  const [isLoadingTemps, setIsLoadingTemps] = useState(true)
  const [current, setCurrent] = useState(null)
  const [temps, setTemps] = useState([])
  const bannerInfo = {
    title: t('templatesList'),
    home: t('home'),
    linkHome: '/',
  }
  const [active, setActive] = useState(null)
  const getCategories = () => {
    Temps.getCategories((res: any) => {
      setCategories(res.data)
      if (res.data[0]) {
        setActive(res.data[0].id)
      }
    }, setIsLoadingCategories)
  }
  const getTemps = () => {
    Temps.getTemplates(
      active,
      (res: any) => {
        setTemps(res.data)
      },
      setIsLoadingTemps
    )
  }
  useEffect(() => {
    getCategories()
  }, [])
  useEffect(() => {
    if (active) {
      getTemps()
    }
  }, [active])
  return (
    <Stack>
      <Stack justifyContent={'space-between'} direction={'row'}>
        <TitlePage info={bannerInfo} />

        <Stack gap={'10px'} direction={'row'}></Stack>
      </Stack>
      <Stack
        gap='24px'
        minHeight={'55vh'}
        p={{xs: '32px', md: '56px 80px'}}
        style={{marginTop: '30px'}}
        className={`card fds sha`}
      >
        <Label align='center' fw='600' fs='36px' text='chooseTheTemplateThatMeetsYourNeeds' />
        {isLoadingCategories ? (
          <SimpleLoading />
        ) : (
          <Tabs data={categories} active={active} setActive={setActive} />
        )}
        <Stack
          sx={{overflowY: 'auto'}}
          minHeight={'40vh'}
          maxHeight={'60vh'}
          width={'100%'}
          position={'relative'}
        >
          {isLoadingTemps ? (
            <Loading />
          ) : temps.length === 0 ? (
            <NoDataTable />
          ) : (
            <Stack
              justifyContent={'center'}
              gap={{xs: '24px', lg: '47px'}}
              direction={'row'}
              flexWrap={'wrap'}
            >
              {temps.map((e: any, i: any) => (
                <TemplateCard setCurrent={setCurrent} data={e} i={e.id} current={current} />
              ))}
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  )
}
