/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {useCatalog, useReport, useCustomer, useCoupons} from './const'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SideMenuItems} from './SideMenuItemCustom'
import {useTranslation} from 'react-i18next'
import {Stack} from '@mui/material'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../app/store'
import {useEffect} from 'react'
import Wallet from '../../../../../app/svg/Wallet'
import AppMakker from '../../../../../app/svg/AppMakker'

const SidebarMenuMain = ({activeMenu, setIsOpen}: any) => {
  const intl = useIntl()
  const store = useSelector((state: RootState) => state.vender)
  const {report} = useReport()
  // const {customer} = useCustomer()
  // const {Coupons} = useCoupons()
  const {t} = useTranslation()

  const catalog = {
    parent: {
      to: '/catalog',
      title: t('catlog'),
      hasBullet: true,
    },
    item: [
      {
        to: '/catalog/list',
        title: store.permissions.includes(`categories_read`) && t('catlogList'),
        hasBullet: true,
      },

      {
        to: '/catalog/option-catalogue',
        title: store.permissions.includes(`option_catalogs_read`) && t('optionCatelogue'),

        hasBullet: true,
      },
      {
        to: '/catalog/options',
        title: store.permissions && store.permissions.includes('options_read') && t('optionsList'),
        hasBullet: true,
      },

      {
        to: '/catalog/manufacturer',
        title:
          store.permissions &&
          store.permissions.includes('manufacturers_read') &&
          t('manufacturers'),

        hasBullet: true,
      },
      {
        to: '/catalog/products',
        title: store.permissions.includes(`products_read`) && t('products'),
        hasBullet: true,
      },
      {
        to: '/catalog/coupons-list',
        title: store.permissions && store.permissions.includes('coupons_read') && t('caponsList'),
        hasBullet: true,
      },
    ],
  }
  const orders = {
    parent: {
      to: '/orders',
      title: t('orders'),
      hasBullet: true,
    },
    item: [
      {
        to: '/orders/orders-list',
        title: store.permissions.includes(`orders_read`) && t('ordersList'),
        hasBullet: true,
      },
      {
        to: '/orders/refund-requests',
        title: store.permissions.includes(`orders_read`) && t('refundRequests'),
        hasBullet: true,
      },
    ],
  }
  const templates = {
    parent: {
      to: '/templates',
      title: t('templates'),
      hasBullet: true,
    },
    item: [
      {
        to: '/templates/list',
        title: true && t('templates'),
        hasBullet: true,
      },
      // {
      //   to: '/templates/templates',
      //   title: true && t('refundRequests'),
      //   hasBullet: true,
      // },
    ],
  }
  const wallet = {
    parent: {
      to: '/wallet',
      title: t('payConnect'),
      hasBullet: true,
    },
    item: [
      {
        to: '/wallet/my-wallet',
        title: t('myWallet'),
        hasBullet: true,
      },

      {
        to: '/wallet/service-fees',
        title: store.permissions && store.permissions.includes('service_fees') && t('serviseFees'),
        hasBullet: true,
      },
      {
        to: '/wallet/Payments-history',
        title: t('PaymentsHistory'),

        hasBullet: true,
      },
    ],
  }
  const storeManagement = {
    parent: {
      to: '/storeManagement',
      title: t('storeManagement'),
      hasBullet: true,
    },
    item: [
      {
        to: '/storeManagement/banner',
        title: store.permissions && store.permissions.includes('banners_read') && t('banners'),
        hasBullet: true,
      },
      {
        to: '/storeManagement/media',
        title: store.permissions && store.permissions.includes('medias_read') && t('medias'),
        hasBullet: true,
      },
      {
        to: '/storeManagement/page',
        title: store.permissions && store.permissions.includes('pages_read') && t('pages'),
        hasBullet: true,
      },
      {
        to: '/storeManagement/menu',
        title: store.permissions && store.permissions.includes('menus_read') && t('menus'),
        hasBullet: true,
      },
      {
        to: '/storeManagement/domain',
        title: t('DomainControl'),
        hasBullet: true,
      },
    ],
  }
  const appMaker = {
    parent: {
      to: '/app-maker',
      title: t('appMaker'),
      hasBullet: true,
    },
    item: [
      {
        to: '/app-maker/ios-application',
        title: t('iosApplication'),
        hasBullet: true,
      },
      {
        to: '/app-maker/android-application',
        title: t('androidApplication'),
        hasBullet: true,
      },
    ],
  }
  const Coupons = {
    parent: {
      to: '/helpdesk',
      title: t('helpDesk'),
      hasBullet: true,
    },
    item: [
      {
        to: '/helpdesk/ticket',
        title: store.permissions && store.permissions.includes('ticket_read') && t('tickets'),
        hasBullet: true,
      },
      {
        to: '/helpdesk/notification',
        title:
          store.permissions &&
          store.permissions.includes('notifications_read') &&
          t('notifications'),
        hasBullet: true,
      },
      {
        to: '/helpdesk/f-a-q',
        title: store.permissions && store.permissions.includes('faq_read') && t('faqs'),
        hasBullet: true,
      },
      {
        to: '/helpdesk/contact',
        title: store.permissions && store.permissions.includes('contacts_read') && t('contacts'),
        hasBullet: true,
      },
    ],
  }
  const Roles = {
    parent: {
      to: '/role/sub-vendor-list',
      title: t('roles'),
      hasBullet: true,
    },
    item: [
      {
        to: '/role/roles-list',
        title: store.permissions && store.permissions.includes('roles_read') && t('rolesList'),
        hasBullet: true,
      },
      {
        to: '/role/sub-vendor-list',
        title: store.permissions && store.permissions.includes('sub-vendor_read') && t('subVendor'),
        hasBullet: true,
      },
    ],
  }
  //  Menu items for siebar menu
  return (
    <Stack gap='15px'>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art011.svg'
        title={t('dashboard')}
        fontIcon='bi-app-indicator'
        activeMenu={activeMenu}
        setIsOpen={setIsOpen}
        activeIcon='/media/icons/activeIcons/2.svg'
      />
      <SideMenuItems
        link='catalog'
        activeMenu={activeMenu}
        parent={catalog.parent}
        item={catalog.item}
        setIsOpen={setIsOpen}
        icon='/media/icons/duotune/art/12.svg'
        activeIcon='/media/icons/activeIcons/5.svg'
      />
      <SideMenuItems
        link='orders'
        activeMenu={activeMenu}
        parent={orders.parent}
        item={orders.item}
        setIsOpen={setIsOpen}
        icon='/media/icons/activeIcons/1.svg'
        activeIcon='/media/icons/activeIcons/1.svg'
      />

      {/* {store.permissions && store.permissions.includes('orders_read') && (
        <SidebarMenuItem
          to='/orders'
          icon='/media/icons/duotune/art/13.svg'
          title={t('orders')}
          fontIcon='bi-app-indicator'
          activeMenu={activeMenu}
          setIsOpen={setIsOpen}
          activeIcon='/media/icons/activeIcons/1.svg'
        />
      )} */}

      {store.permissions.includes(`customers_read`) && (
        <SidebarMenuItem
          to='/customer/list'
          title={t('customers')}
          icon='/media/icons/duotune/art/14.svg'
          fontIcon='bi-app-indicator'
          activeMenu={activeMenu}
          setIsOpen={setIsOpen}
          activeIcon='/media/icons/activeIcons/4.svg'
        />
      )}
      {store.permissions && store.permissions.includes('chats_read') && (
        <SidebarMenuItem
          to='/live-chat'
          icon='/media/icons/duotune/msg.svg'
          title={t('liveChat')}
          fontIcon='bi-app-indicator'
          activeMenu={activeMenu}
          setIsOpen={setIsOpen}
          activeIcon='/media/icons/activeIcons/msg.svg'
        />
      )}
      <SideMenuItems
        link='wallet'
        activeMenu={activeMenu}
        parent={wallet.parent}
        item={wallet.item}
        setIsOpen={setIsOpen}
        icon={<Wallet />}
        activeIcon={<Wallet />}
      />
      <SideMenuItems
        link='helpdesk'
        activeMenu={activeMenu}
        parent={Coupons.parent}
        item={Coupons.item}
        setIsOpen={setIsOpen}
        icon='/media/icons/duotune/art/16.svg'
        activeIcon='/media/icons/activeIcons/3.svg'
      />
      <SideMenuItems
        link='storeManagement'
        activeMenu={activeMenu}
        parent={storeManagement.parent}
        item={storeManagement.item}
        setIsOpen={setIsOpen}
        icon='/media/icons/duotune/art/19.svg'
        activeIcon='/media/icons/activeIcons/7.svg'
      />
      <SideMenuItems
        link='app-maker'
        activeMenu={activeMenu}
        parent={appMaker.parent}
        item={appMaker.item}
        setIsOpen={setIsOpen}
        icon={<AppMakker />}
        activeIcon={<AppMakker />}
      />
      <SideMenuItems
        link='role'
        activeMenu={activeMenu}
        parent={Roles.parent}
        item={Roles.item}
        setIsOpen={setIsOpen}
        icon='/media/icons/duotune/vendor.svg'
        activeIcon='/media/icons/activeIcons/vendor.svg'
      />
      <SidebarMenuItem
        to='/subscriptions'
        icon='/media/icons/duotune/sub.svg'
        title={t('subscriptions')}
        fontIcon='bi-app-indicator'
        activeMenu={activeMenu}
        setIsOpen={setIsOpen}
        activeIcon='/media/icons/activeIcons/sub.svg'
      />
      <SideMenuItems
        link='templates'
        activeMenu={activeMenu}
        parent={templates.parent}
        item={templates.item}
        setIsOpen={setIsOpen}
        icon={<AppMakker />}
        activeIcon={<AppMakker />}
      />
    </Stack>
  )
}

export {SidebarMenuMain}
