import {FC, lazy, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import CategoryPage from '../modules/category/categoryPage'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import Banner from '../modules/banner'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import {Stack} from '@mui/material'
import SittingPage from '../modules/sittings'
import {VendorProfile} from '../modules/vendorProfile/VendorProfile'
import Orders from '../modules/orders'
import View from '../modules/orders/View/View'
import StoreManagement from '../modules/storeManagement/StoreManagement'
import ChatComponent from '../modules/chatRooms/Chat'
import Subscriptions from '../modules/subscriptions'
import ChangePackage from '../modules/subscriptions/ChangePackage'
import {useSelector} from 'react-redux'
import {RootState} from '../store'
import Error403 from '../modules/errors/components/Error403'
import {Error500} from '../modules/errors/components/Error500'
import Error401 from '../modules/errors/components/Error401'
import ServesFees from '../modules/servesFees'
import InvoiceForm from '../modules/servesFees/InvoiceForm'
import Wallet from '../modules/wallet'
import WalletPage from '../modules/wallet/walletPage'
import AppMakerPage from '../modules/storeManagement/applicationMaker/AppMakerPage'
import SubscriptionsDate from '../modules/subscriptions/dateTable'
import WarperBackages from '../modules/customTable/WarperBackages'
import RefundRequests from '../modules/refundRequests'
import ViewRefundRequests from '../modules/refundRequests/View/View'
import RefundListing from '../modules/refundListing'
import ViewRefundListing from '../modules/refundListing/View/View'
import OrdersPage from '../modules/orders/OrdersPage'
import Error503 from '../modules/errors/components/Error503'
import TemplatesPage from '../modules/templates/TemplatesPage'
// import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
// import { MenuTestPage } from '../pages/MenuTestPage'
const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
const RolesPage = lazy(() => import('../modules/roules/Listroles'))
const CustomerPage = lazy(() => import('../modules/customer/customerPage'))
const OptionsPage = lazy(() => import('../modules/options/optionsPage'))
const CouponPage = lazy(() => import('../modules/Coupons/couponPage'))

// const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
// const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
// const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
// const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
// const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

const PrivateRoutes = () => {
  const store = useSelector((state: RootState) => state.vender)

  const rolesBreadCrumbs: Array<PageLink> = [
    {
      title: 'Home',
      path: '/',
      isSeparator: false,
      isActive: true,
    },
  ]
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='403' element={<Error403 />} />
        <Route path='503' element={<Error503 />} />
        <Route path='401' element={<Error401 />} />
        <Route path='500' element={<Error500 />} />{' '}
        <Route path='subscriptions' element={<Subscriptions />} />
        <Route path='subscriptions-date' element={<SubscriptionsDate />} />
        <Route path='subscriptions-change-package' element={<ChangePackage />} />
        <Route
          path='live-chat'
          element={
            store.permissions && store.permissions.includes('chats_read') ? (
              <>
                <ChatComponent />{' '}
              </>
            ) : (
              <Error403 />
            )
          }
        />
        {/* <Route path='banner' element={<Banner />} /> */}
        <Route path='settings' element={<SittingPage />} />
        {/* <Route path='builder' element={<BuilderPageWrapper />} /> */}
        {/* <Route path='menu-test' element={<MenuTestPage />} /> */}
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        {/* <Route
                         path='crafted/pages/customer/*'
                         element={
                              <SuspensedView>
                                   <CustomerPage />
                              </SuspensedView>
                         }
                    /> */}
        <Route
          path='role/*'
          element={
            <SuspensedView>
              <RolesPage />
            </SuspensedView>
          }
        />
        <Route
          path='orders/*'
          element={
            <SuspensedView>
              <OrdersPage />
            </SuspensedView>
          }
        />
        <Route
          path='catalog/*'
          element={
            <SuspensedView>
              <CategoryPage />
            </SuspensedView>
          }
        />
        <Route
          path='storeManagement/*'
          element={
            <SuspensedView>
              <StoreManagement />
            </SuspensedView>
          }
        />
        <Route
          path='customer/*'
          element={
            <SuspensedView>
              <CustomerPage />
            </SuspensedView>
          }
        />
        <Route
          path='report/*'
          element={
            <SuspensedView>
              <OptionsPage />
            </SuspensedView>
          }
        />
        <Route
          path='helpdesk/*'
          element={
            <SuspensedView>
              <CouponPage />
            </SuspensedView>
          }
        />
        <Route
          path='wallet/*'
          element={
            <SuspensedView>
              <WalletPage />
            </SuspensedView>
          }
        />
        <Route
          path='templates/*'
          element={
            <SuspensedView>
              <TemplatesPage />
            </SuspensedView>
          }
        />
        <Route
          path='app-maker/*'
          element={
            <SuspensedView>
              {store.permissions && store.permissions.includes('app_maker') ? (
                <AppMakerPage />
              ) : (
                <Error403 />
              )}
            </SuspensedView>
          }
        />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
