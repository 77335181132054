import {useCallback, useEffect, useMemo, useState} from 'react'
import Dropzone, {DropzoneState} from 'react-dropzone'
import {useLocation, useParams} from 'react-router-dom'
import Img from '../../../../componet/imag'
import {RootState, useDispatch, useSelector, withToastr} from '../../../../store'
import {
  getProductById,
  uploadProductFileImage,
  deleteProductImageAction,
} from '../../../../store/vender/products/thunk'
import {ActionBackOrNext} from './actionfooter'
import {useTranslation} from 'react-i18next'
import {Stack, Typography} from '@mui/material'
import {toast} from 'react-toastify'
import {actions} from '../../../../store/vender/Auth/auth.slice'
import MainBtn from '../../../customTable/MainBtn'
import PupUpImage from '../../../customTable/PupUpImage'
import SizeImage from '../../../customTable/SizeImage'
import Label from '../../../customTable/Label'
import Videos from './Videos'

type SelectedFile = File & {preview: string; formattedSize: string}

interface selectedFilesFound {
  id: string
  preview: string
  name: string
  formattedSize: string
  type?: any
}

interface FileZoneProps {
  setActiveKey: React.Dispatch<React.SetStateAction<string>>
  myProduct?: any
  formik?: any
}
export const FileZone = ({setActiveKey, myProduct, formik}: FileZoneProps): JSX.Element => {
  const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>([])
  const [selectedFilesFound, setSelectedFilesFound] = useState<selectedFilesFound[]>([])

  //   need to  take  id  from  the  url
  const {id} = useParams<{id?: string}>()

  function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }
  const dispatch = useDispatch()
  const store = useSelector((state: RootState) => state.vender)

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const formattedFiles = acceptedFiles.map<SelectedFile>((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setSelectedFiles((prevSelectedFiles: Array<SelectedFile>) => {
      return [...prevSelectedFiles, ...formattedFiles]
    })
  }, [])
  const [loading, setLoading] = useState(false)
  const {product} = useSelector((state: RootState) => state.product)
  const location = useLocation()

  //   on uulode file=
  const onUploadAction = useCallback(async () => {
    if (!id || !selectedFiles.length) return
    setLoading(true)
    const images = (await Promise.all(
      selectedFiles.map((file) => {
        return new Promise((resolve) => {
          const reader = new FileReader()
          reader.onload = () => {
            const dataUrl = reader.result
            const base64 = dataUrl
            resolve(base64)
          }
          reader.readAsDataURL(file)
        })
      })
    )) as string[]
    dispatch(uploadProductFileImage({id, images})).then((res: any) => {
      console.log(res, 'roro')

      setLoading(false)
      setIsUpload(true)
      setSelectedFiles([])

      // toast.success('Uploaded Successfully')
    })
  }, [dispatch, id, selectedFiles])

  const fetchData = useCallback(() => {
    if (id) {
      // dispatch(getProductById(id))
    }
  }, [dispatch, id])
  useEffect(() => {
    id && fetchData()
  }, [fetchData, id])

  useEffect(() => {
    if (product?.product_pictures?.length) {
      const files = {...product, ...myProduct}?.product_pictures
        .filter((e: any) => e.media_type !== 'video')
        .map((file: any) => {
          const imageNameWithExtension = file?.picture.substring(file?.picture.lastIndexOf('/') + 1)
          const imageName = imageNameWithExtension.substring(
            0,
            imageNameWithExtension.lastIndexOf('.')
          )

          return {
            id: file.id,
            preview: file.picture,
            name: imageName,
          }
        })

      setSelectedFilesFound(files)
    }
  }, [product?.product_pictures])

  const handleDeleteWithId = useCallback(
    (item_id: string) => {
      if (!id || !item_id) return
      const newSelectedFiles = [...selectedFilesFound]

      dispatch(deleteProductImageAction({id: item_id, product_id: id})).then(() => {
        const index = newSelectedFiles.findIndex((file) => file.id === item_id)
        if (index > -1) {
          newSelectedFiles.splice(index, 1)
        }

        setSelectedFilesFound(newSelectedFiles)
      })
    },
    [dispatch, id, selectedFilesFound, setSelectedFilesFound]
  )

  const handleDelete = (item: SelectedFile) => {
    const newSelectedFiles = [...selectedFiles]
    const index = newSelectedFiles.findIndex((file) => file.name === item.name)

    if (index > -1) {
      newSelectedFiles.splice(index, 1)
    }
    setSelectedFiles(newSelectedFiles)
  }
  const {t} = useTranslation()
  const [isUpload, setIsUpload] = useState(false)
  const [isImage, setIsImage] = useState(true)
  return (
    <div>
      <div className='card card-custom card-stretch gutter-b'>
        <div className='card card-flush py-4'>
          <div className='card-header'>
            <div className='card-title'>
              <h2>{t('media')}</h2>
            </div>
          </div>
          <Stack
            width={'fit-content'}
            gap='15px'
            p='8px'
            bgcolor={'#E3E7EF'}
            borderRadius={'6px'}
            direction={'row'}
            m='29.25px'
          >
            <Stack
              sx={{cursor: 'pointer'}}
              bgcolor={!isImage ? '#E3E7EF' : '#405189'}
              borderRadius={'6px'}
              p='13px 25px'
              onClick={() => {
                console.log(111, isImage)

                setIsImage(true)
              }}
            >
              <Label color={isImage ? '#fff' : '#000'} text='images' />
            </Stack>
            <Stack
              sx={{cursor: 'pointer'}}
              p='13px 25px'
              bgcolor={isImage ? '#E3E7EF' : '#405189'}
              borderRadius={'6px'}
              onClick={() => {
                setIsImage(!true)
              }}
            >
              <Label color={!isImage ? '#fff' : '#000'} text='videos' />
            </Stack>
          </Stack>
          {isImage ? (
            <>
              {' '}
              <div className='card-body pt-0'>
                <div className='fv-row mb-2'>
                  <Dropzone onDrop={onDrop}>
                    {({getRootProps, getInputProps, isDragActive}: DropzoneState) => (
                      <div
                        className={`dropzone border border-danger border-dashed rounded ${
                          isDragActive ? 'active' : ''
                        }`}
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className='mb-3'>
                          <i className='display-4 text-muted bx bxs-cloud-upload' />
                        </div>
                        <h4>{t('dropfiles')} </h4>
                      </div>
                    )}
                  </Dropzone>
                  <Stack alignItems={'center'} mt='8px'>
                    <SizeImage width={'product_width'} hight={'product_height'} />
                  </Stack>

                  <div
                    className='dropzone-previews mt-3 d-flex flex-column g-2  gap-2 px-4 '
                    id='file-previews'
                  >
                    {selectedFiles?.map((file: SelectedFile, i: number) => (
                      <div
                        className='col-12 mb-2 shadow-none border dz-processing dz-image-preview dz-danger dz-complete '
                        key={i + '-file'}
                      >
                        {' '}
                        <div className='row align-items-center'>
                          <div className='col-9 d-flex align-items-center'>
                            <PupUpImage image={file.preview}>
                              <div className='d-flex px-2'>
                                <Img
                                  data-dz-thumbnail=''
                                  height='100'
                                  width='100%'
                                  style={{maxWidth: '90px', maxHeight: '90px'}}
                                  className='avatar-sm rounded bg-light w-100 my-2 p-1'
                                  alt={file.name}
                                  src={file.preview}
                                />
                              </div>{' '}
                            </PupUpImage>

                            {/* <span className='text-muted font-weight-bold'>{file.name}</span> */}
                            <p className='mb-0'>
                              <strong>{file.formattedSize}</strong>
                            </p>
                          </div>
                          <div className='col-3 px-2 '>
                            <div className='d-flex   align-content-center justify-content-center'>
                              <span
                                onClick={() => {
                                  handleDelete(file)
                                }}
                                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                                title='remove image'
                              >
                                <i className='bi bi-x fs-2'></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='fv-row mb-2'>
                <div className='row card-body pt-0'>
                  <div className='mt-3 d-flex flex-column g-2  gap-2 px-4' id='file-previews'>
                    {selectedFilesFound?.map((file: selectedFilesFound, i: number) => (
                      <div
                        className='col-12 mb-2 shadow-none border dz-processing dz-image-preview dz-danger dz-complete'
                        key={i + '-file'}
                      >
                        <div className='row align-items-center'>
                          <div className='col-9 d-flex align-items-center'>
                            {' '}
                            <PupUpImage image={file.preview}>
                              <div className='d-flex px-2'>
                                <Img
                                  src={file.preview}
                                  height='100'
                                  width='100%'
                                  style={{maxWidth: '90px', maxHeight: '90px'}}
                                  className='avatar-sm rounded bg-light w-100 my-2 p-1'
                                  alt={file.preview}
                                />
                              </div>
                              {/* <div>
                            <span className='text-muted font-weight-bold'>{file.name}</span>
                            <p className='mb-0'>
                              <strong>{file.formattedSize}</strong>
                            </p>
                          </div>{' '} */}
                            </PupUpImage>
                          </div>
                          <div className='col-3 px-2 '>
                            <div className='d-flex   align-content-center justify-content-center'>
                              <span
                                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                                title='remove image'
                              >
                                <i
                                  className='bi bi-x fs-2'
                                  onClick={() => {
                                    handleDeleteWithId(file.id)
                                  }}
                                ></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {selectedFiles.length === 0 && isUpload && (
                    <Typography sx={{color: '#0ac60a'}}>{t('uploadedSuccessfully')}</Typography>
                  )}
                </div>
              </div>
              {selectedFiles.length > 0 && (
                <div className='card-footer d-flex justify-content-end '>
                  <MainBtn
                    width='auto'
                    isLoading={loading}
                    handelSubmit={onUploadAction}
                    text='upload'
                  />
                </div>
              )}
            </>
          ) : (
            <Videos formik={formik} />
          )}
        </div>
      </div>
      <ActionBackOrNext setActiveKey={setActiveKey} backTo={'Links'} nextTo={'Options'} />
    </div>
  )
}
