import {Stack} from '@mui/material'
import React from 'react'
import MainBtn from '../../customTable/MainBtn'
import Label from '../../customTable/Label'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'

export default function PayForm({price, duration, isLoading, submit, name}: any) {
  const today = new Date()
  const formattedDate = today.toLocaleDateString()
  const {lang, defLang} = useSelector((state: RootState) => state.vender)

  return (
    <Stack p='48px'>
      <Stack gap='10px'>
        <Label text='checkout' fs='20px' fw='500' />
        <Stack pb={'32px'} borderBottom='1px solid #000' gap='16px'>
          <Stack mt='40px' justifyContent={'space-between'} direction={'row'}>
            <Label
              text={name && (name[lang] || name[defLang])}
              fs={{xs: '14px', sm: '24px'}}
              fw='500'
            />
            <Label text={price} color='#65656A' fs={{xs: '14px', sm: '24px'}} fw='500' />
          </Stack>
          <Stack justifyContent={'space-between'} direction={'row'}>
            <Stack alignItems={'center'} gap='5px' direction={'row'}>
              <Label text={`startDate`} color='#65656A' fs='16px' fw='600' />:
              <Label text={`${formattedDate}`} color='#65656A' fs='16px' fw='600' />
            </Stack>
            <Label text={duration} fs='14px' fw='400' />
          </Stack>
        </Stack>

        <Stack pt='10px' gap='20px'>
          <Label align='center' text='payWith' fs='14px' fw='600' />
          <Stack alignItems={'center'}>
            <MainBtn
              p='12px 20px'
              handelSubmit={submit}
              isLoading={isLoading}
              width={{sm: '350px', xs: '100%'}}
              text='konin2pay'
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
