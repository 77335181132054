import {Stack} from '@mui/material'
import React from 'react'
import Links from './Links'
import GetStart from './GetStart'
import {HeaderUserMenu} from '../../../_metronic/partials'
import {Navbar} from './Navbar'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

export default function Header() {
  return (
    <Stack
      p={{xs: '30px 30px', md: '30px 135px'}}
      alignItems={'center'}
      direction={'row'}
      justifyContent={'space-between'}
    >
      <Stack position={'sticky'} top={'0'} height={'43px'} width={{sm: '101px ', xs: '80px'}}>
        <img
          style={{width: '100%', height: '100%'}}
          src={toAbsoluteUrl('/media/avatars/logo.svg')}
          alt=''
        />
      </Stack>
      {/* <Stack display={{md: 'block', xs: 'none'}}>
        <Links />
      </Stack> */}
      <GetStart />
    </Stack>
  )
}
