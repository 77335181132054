import Apiservices from '../../lib/ApiServices'
import ApisMethods from './apisMethods'

const Options = {
  get: (
    searchText: string,
    rowsPerPage: number,
    page: number,
    callback: any,
    setIsLoading: any
  ) => {
    let url = `/vendor/options?page=${page}&per_page=${rowsPerPage}`
    if (searchText) {
      url = url + `&search=${searchText}`
    }
    ApisMethods().get(url, callback, setIsLoading)
  },
  add: (data: any, callback: any, setIsLoading: any) => {
    console.log(data, 'option12')
    let newData = {
      ...data,
      optionDetails:
        data.value_type === 'text'
          ? data.optionDetails.map((e: any) => {
              let a = {...e}
              delete a.option_image
              delete a.option_code
              return a
            })
          : data.value_type === 'image'
          ? data.optionDetails.map((e: any) => {
              let a = {...e}
              delete a.option_code
              return a
            })
          : data.value_type === 'color'
          ? data.optionDetails.map((e: any) => {
              let a = {...e}
              delete a.option_image
              return a
            })
          : data.optionDetails,
    }

    ApisMethods().post(`/vendor/options`, newData, callback, setIsLoading)
  },
  update: (id: any, data: any, callback: any, setIsLoading: any) => {
    ApisMethods().put(`/vendor/options/${id}`, data, callback, setIsLoading)
  },
  put: (option: any, id: string | undefined, callback: any, setIsLoading: any) => {
    ApisMethods().put(
      `/vendor/options/${id}`,
      {
        option_catalog_id: option.option_catalog_id.value,
        name: option.name,
      },
      callback,
      setIsLoading
    )
  },
  destroy: (id: number, callback: any, setIsLoading: any) => {
    ApisMethods().destroy(`/vendor/options/${id}`, callback, setIsLoading)
  },
  addOption: (option: any, id: string | undefined, callback: any, setIsLoading: any) => {
    ApisMethods().post('/vendor/option-details', {...option, option_id: id}, callback, setIsLoading)
  },
  putOption: (option: any, callback: any, setIsLoading: any) => {
    if (option.option_code) {
      ApisMethods().put(
        `/vendor/option-details/${option.id}`,
        {
          option_id: option.option_id,
          option_value: option.option_value,
          sort: option.sort,
          option_code: option.option_code,
        },
        callback,
        setIsLoading
      )
    } else if (
      !option.option_code &&
      option.option_image !== 'https://vendor.api.koninstore.com/storage/store/options'
    ) {
      if (option.option_image && option.option_image.slice(0, 4) === 'data') {
        ApisMethods().put(
          `/vendor/option-details/${option.id}`,
          {
            option_id: option.option_id,
            option_value: option.option_value,
            sort: option.sort,
            option_image: option.option_image,
          },
          callback,
          setIsLoading
        )
      } else {
        ApisMethods().put(
          `/vendor/option-details/${option.id}`,
          {
            option_id: option.option_id,
            option_value: option.option_value,
            sort: option.sort,
          },
          callback,
          setIsLoading
        )
      }
    } else {
      ApisMethods().put(
        `/vendor/option-details/${option.id}`,
        {
          option_id: option.option_id,
          option_value: option.option_value,
          sort: option.sort,
        },
        callback,
        setIsLoading
      )
    }
  },
  destoyOption: async (option: any, callback: any, setIsLoading: any) => {
    ApisMethods().destroy(
      `/vendor/option-details/${option.id}?option_id=${option.option_id}`,
      callback,
      setIsLoading
    )
  },
  getOptionsById: (id: string | undefined, callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/products-options/ids?product_id=${id}`, callback, setIsLoading)
  },
  getOptionById: async (
    id: string | undefined,
    optionId: any,
    callback: any,
    setIsLoading: any
  ) => {
    ApisMethods().get(
      `/vendor/products-options/edit/${optionId}?product_id=${id}?`,
      callback,
      setIsLoading
    )
  },
  getOptionsName: (callback: any, setIsLoading: any) => {
    ApisMethods().get('/vendor/option_catalogs/get/names', callback, setIsLoading)
  },
  changeStatus: (id: string, data: {status: number}, callback: any, setIsLoading: any) => {
    ApisMethods().put(`/vendor/options/status/${id}`, data, callback, setIsLoading)
  },
}
export default Options
