import React, {useState, useCallback, useEffect} from 'react'
import HeadTable from '../../customTable/HeadTable'
import TableHead from './TablHead'
import Row from './Row'
import Banner from '../../../Api/banner'
import {PaginationSection} from '../../customTable/pagnationSection'
import {CustomerListLoading} from '../../customer/order/custmerListLoading'
import {CircularProgress, Stack} from '@mui/material'
import IBanner from '../../../interface/IBanner'
import Manufacturers from '../../../Api/manufacturers'
import Search from '../../customTable/Search'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import RowBtns from './RowBtns'

export default function Table({rows, setRows, packageById}: any) {
  const [isLoading, setIsLoading] = useState(false)
  console.log(packageById, 'packageById')

  const [added, setAdded] = useState(false)
  const {t} = useTranslation()
  const [dataTable, setDataTable] = useState({})
  const [newDataTable, setNewDataTable] = useState<any>([])
  const [prices, setPrices] = useState({})
  const [duration, setDuration] = useState({})
  const [freeId, setfreeId] = useState({})
  const [paidId, setPaidId] = useState({})
  const [paidDurationId, setPaidDurationId] = useState({})
  const [name, setName] = useState({})
  const [type, setType] = useState({})
  const [isMostPopular, setIsMostPopular] = useState({})
  const [discount, setDiscount] = useState({})
  const [freeTrialDuration, setFreeTrialDuration] = useState({})
  useEffect(() => {
    let item: any = {}
    packageById.map((e: any, i: any) => {
      for (const key in e) {
        if (key === 'features') {
          e.features.map((ele: any, index: any) => {
            for (const k in ele) {
              item = {
                ...item,
                [ele.feature_key]: {
                  ...item[ele.feature_key],
                  [`${i}`]: ele,
                  name: ele.feature_name,
                },
              }
            }
          })
        } else if (key === 'discount_amount') {
          item = {
            ...item,
            [key]: {
              ...item[key],
              [`${i}`]:
                e.discount_type === 'percentage' || e.discount_type === 'both' ? `${e[key]}` : null,
            },
          }
        } else if (key === 'free_trial_duration') {
          item = {
            ...item,
            [key]: {
              ...item[key],
              [`${i}`]:
                e.discount_type !== 'percentage'
                  ? `+${e['added_duration']} ${
                      e.added_duration_unit === 'monthly' ? 'Mo.' : 'Y.'
                    } free`
                  : null,
            },
          }
        } else if (
          key === 'color' ||
          key === 'name' ||
          key === 'type' ||
          key === 'paid_duration_id' ||
          key === 'paid_id' ||
          key === 'free_id' ||
          key === 'discount_type' ||
          key === 'is_most_popular'
        ) {
          item = {
            ...item,
            [key]: {
              ...item[key],
              [`${i}`]: e[key],
            },
          }
        } else if (key === 'price') {
          item = {
            ...item,
            [key]: {
              ...item[key],
              [`${i}`]: `${e[key]} ${localStorage.getItem('currency')}`,
            },
          }
        } else if (key === 'duration') {
          item = {
            ...item,
            [key]: {
              ...item[key],
              [`${i}`]: `${e[key]} ${t(e['duration_unit'])}`,
            },
          }
        }
      }
    })
    console.log(item, freeTrialDuration, discount, 'vfvfv')

    let arr: any = []

    for (const key in item) {
      if (key === 'duration') {
        arr = [...arr, {...item[key], name: item[key].name ? item[key].name : key}]
        setDiscount(item['discount_amount'])
        setDuration(item['duration'])
        setFreeTrialDuration(item['free_trial_duration'])
      } else if (key === 'color') {
        setDataTable(item['color'])
      } else if (key === 'free_id') {
        setfreeId(item['free_id'])
      } else if (key === 'paid_id') {
        setPaidId(item['paid_id'])
      } else if (key === 'paid_duration_id') {
        setPaidDurationId(item['paid_duration_id'])
      } else if (key === 'name') {
        setName(item['name'])
      } else if (key === 'type') {
        setType(item['type'])
      } else if (key === 'price') {
        setPrices(item['price'])
        setIsMostPopular(item['is_most_popular'])
        arr = [...arr, {...item[key], name: item[key].name ? item[key].name : key}]
      } else {
        arr = [...arr, {...item[key], name: item[key].name ? item[key].name : key}]
      }
    }
    setNewDataTable(arr)
  }, [packageById])
  console.log(isMostPopular, 'newDataTable')

  return (
    <div className={`card fds sha pt-5`}>
      <div className='card-body py-3 pb-5'>
        <div className='table-responsive overflow-auto  '>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <TableHead
              isMostPopular={isMostPopular}
              data={packageById.map((e: any, i: any) => {
                return {
                  name: e.name,
                  key: i,
                }
              })}
            />
            {!added ? (
              <>
                {' '}
                {!isLoading ? (
                  <tbody className='mb-4'>
                    {newDataTable.map((e: any, i: any) => (
                      <Row
                        index={i}
                        discount={discount}
                        setIsLoading={setIsLoading}
                        setRows={setRows}
                        rows={rows}
                        item={e}
                        freeTrialDuration={freeTrialDuration}
                      />
                    ))}

                    <RowBtns
                      paidId={paidId}
                      freeId={freeId}
                      paidDurationId={paidDurationId}
                      type={type}
                      name={name}
                      duration={duration}
                      prices={prices}
                      colors={dataTable}
                    />
                  </tbody>
                ) : (
                  <CircularProgress />
                )}
              </>
            ) : (
              <h1>loading</h1>
            )}
          </table>

          {/* pagnation  item   */}
        </div>
      </div>
    </div>
  )
}
