import {Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export default function Label({text, fs, fw, color, align, mw, sx}: any) {
  const {t} = useTranslation()
  return (
    <Typography
      align={align || 'start'}
      sx={{
        color: color || '#19224C',
        fontSize: fs || '16px',
        fontWeight: fw || '400',
        textTransform: 'capitalize',
        maxWidth: mw || 'auto',
        ...sx,
      }}
    >
      {t(text)}
    </Typography>
  )
}
