import ApisMethods from './apisMethods'

const General = {
  get: (callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/settings/general`, callback, setIsLoading)
  },

  put: async (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/settings/general`, data, callback, setIsLoading)
  },
  EditOrder: async (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/settings/orders`, data, callback, setIsLoading)
  },
  GetOrder: (callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/settings/orders`, callback, setIsLoading)
  },
  editWiteLabel: (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/setting/update-white-label`, data, callback, setIsLoading)
  },
}
export default General
