import {Stack} from '@mui/material'
import React from 'react'
import Label from '../../customTable/Label'
import FixedNumber from '../../customTable/FixedNumber'

export default function LabelWithValue({label, value, isFixed}: any) {
  return (
    <Stack flexWrap={'wrap'} alignItems={'center'} gap='5px' direction={'row'}>
      <Label color='#19224C' fw='500' fs='12px' text={label} />:
      {isFixed ? (
        <FixedNumber color='#787887' fw='500' fs='10px' num={value} />
      ) : (
        <Label color='#787887' fw='500' fs='10px' text={value ? value : '-'} />
      )}
    </Stack>
  )
}
