import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import {TransitionProps} from '@mui/material/transitions'
import {useTranslation} from 'react-i18next'
import {Stack, Typography} from '@mui/material'
import MainBtn from './MainBtn'
import DeleteIcon from '../../svg/DeleteIcon'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

export default function DeletedMsg({open, setOpen, onClick, deleteMsg, isLoadingDelete}: any) {
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    if (!isLoadingDelete) {
      setOpen(false)
    }
  }

  return (
    <div>
      <></>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth='sm'
        fullWidth={true}
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
        sx={{
          '& .MuiPaper-root': {
            backgroundColor: 'var(--bs-body-bg)', // تعديلاتك الخاصة هنا
          },
          '& .MuiDialog-paperWidthSm': {},
        }}
      >
        <Stack lang={store.lang} dir={store.isRtl === 0 ? 'ltr' : 'rtl'} p='15px'>
          <DialogContent>
            <DialogContentText id='alert-dialog-slide-description'>
              <Stack alignItems={'center'} direction={'row'} gap='16px'>
                <Stack width={'150px'}>
                  <DeleteIcon />
                </Stack>
                <Typography
                  sx={{
                    fontSize: '18px',
                    color: 'var(--bs-heading-color, inherit)',
                    textAlign: 'center',
                  }}
                >
                  {t(deleteMsg || 'deleteMsg')}
                </Typography>
              </Stack>
            </DialogContentText>
          </DialogContent>
          <Stack justifyContent={'flex-end'} direction={'row'} gap='15px'>
            <MainBtn without width='auto' text='no' handelSubmit={handleClose} />
            <MainBtn
              isLoading={isLoadingDelete}
              width='auto'
              text='yes'
              handelSubmit={() => {
                onClick()
                // setOpen(false)
              }}
            />
          </Stack>
        </Stack>
        {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
      </Dialog>
    </div>
  )
}
