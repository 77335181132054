import {createAsyncThunk} from '@reduxjs/toolkit'
import store from '../..'
import API from '../../../Api'
import {thunkCatchError} from '../../utils'
import {IPageCatogories} from './../optionCategory/types.d'
import {toast} from 'react-toastify'

const PRODUCTS = {
  GET_PRODUCTS: '/vendor/products',
  GET_PRODUCTS_NAME: '/vendor/products/get/names',
  UPLOAD: '/vendor/products/images/store',
  DELETE_IMAGE: '/vendor/products/images/delete',
  BLUK: '/vendor/products/bulk-discount',
}

export const ProductsAction = createAsyncThunk(
  'product/products',
  thunkCatchError(async ({page = 1, pre_page = 10, search}: IPageCatogories) => {
    let query = `?page=${page}&per_page=${pre_page}`
    if (search) {
      query += `&search=${search}`
    }
    const URL = `${PRODUCTS.GET_PRODUCTS}${query}`

    const response = await API.get(URL)
    return (
      {
        data: response.data?.data,
        pages: response.data?.pages,
      } || {}
    )
  })
)
export const deleteProductAction = createAsyncThunk(
  'product/deleteProductAction',
  thunkCatchError(async (id: string) => {
    const item = await API.delete(`${PRODUCTS.GET_PRODUCTS}/${id}`)

    //    we  need  to delete  data  form  store  after  delete  from  server
    const {data, pages} = store.getState()?.product?.products

    const newData = data?.filter((item: any) => {
      return item.id + '' !== id
    })

    return {
      data: newData,
      pages,
    }
  })
)
// export const categoryDetailsAction = createAsyncThunk(
//   'product/categories',
//   thunkCatchError(async ({page = 1, pre_page = 15, id}: IGetPRODUCTSDetails) => {
//     const response = await API.get(
//       `${PRODUCTS.GET_PRODUCTS}/${id}?page=${page}&per_page=${pre_page}`
//     )
//     return (
//       {
//         data: response.data?.data,
//         pages: response.data?.pages,
//       } || {}
//     )
//   })
// )

// export const deleteCategoryAction = createAsyncThunk(
//   'product/deleteCategory',
//   thunkCatchError(async (id: string) => {
//     await API.delete(`${PRODUCTS.GET_PRODUCTS}/${id}`)
//     //    we  need  to delete  data  form  store  after  delete  from  server
//     const {data, pages} = store.getState().category.categories
//     const newData = data.filter((item: any) => item.id !== id)
//     //  we  need to  update the pages
//     if (pages?.current_page === pages?.last_page && pages?.last_page > 1) {
//       // total
//       pages.total = pages.total - 1
//       pages.current_page = pages.current_page - 1
//     }
//     //       if  current_page is  1  and  last_page  is  1  then  we  need  to  update  total
//     if (pages?.current_page === 1 && pages?.last_page === 1) {
//       pages.total = pages.total - 1
//     }

//     //  we  need  to  update  store  after  delete  from  server
//     return {
//       data: newData,
//       pages,
//     }
//   })
// )

// //  we need  to  conver  file image to  base64  and  send  to  server

// function convertFileToBase64(file: File): Promise<string> {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader()
//     reader.readAsDataURL(file)
//     reader.onload = () => {
//       const base64String = reader.result?.toString().split(',')[1]

//       if (base64String) {
//         resolve(base64String)
//       } else {
//         reject('Error converting file to Base64')
//       }
//     }
//     reader.onerror = () => {
//       reject('Error reading file')
//     }
//   })
// }

// //   create  one  PRODUCTS
// export const createCategoryAction = createAsyncThunk(
//   'product/createCategory',
//   thunkCatchError(async (inputData: any) => {
//     //   need to  conver  image  to  base
//     const response = await API.post(PRODUCTS.GET_PRODUCTS, inputData)
//     return response.data?.data || {}
//   })
// )

//   get PRODUCTSName
export const getProductName = createAsyncThunk(
  'product/getProductName',
  thunkCatchError(async () => {
    const response = await API.get(`${PRODUCTS.GET_PRODUCTS_NAME}`)

    return response.data?.data || {}
  })
)
//   get  product  by id
export const getProductById = createAsyncThunk(
  'product/getProductById',
  thunkCatchError(async (id: string) => {
    try {
      const response = await API.get(`${PRODUCTS.GET_PRODUCTS}/${id}`)
      return response.data?.data || {}
    } catch (err: any) {
      return {errorStatus: err.response.status}
      console.log(err.response, 'errd')
    }
  })
)
// createProductAction
export const createProductAction = createAsyncThunk(
  'product/createProductAction',
  thunkCatchError(async (inputData: any) => {
    const isImageBase64 = inputData.image?.includes('data:image')
    if (!isImageBase64) {
      delete inputData.image
    }

    inputData.related_products = inputData?.related_products?.map((x: any) => x.value)
    inputData.categories = inputData?.categories?.map((x: any) => x.value)

    const response = await API.post(PRODUCTS.GET_PRODUCTS, {
      ...inputData,
      quantity: inputData.quantity ? inputData.quantity : 0,
    })

    return response.data?.data || {}
  })
)

export const updateProductAction = createAsyncThunk(
  'product/updateProductAction',
  thunkCatchError(async ({id, inputData}: {id: number; inputData: any}) => {
    try {
      const isImageBase64 = inputData.image?.includes('data:image')
      if (!isImageBase64) {
        delete inputData.image
      }
      inputData.related_products = inputData?.related_products?.map((x: any) => x.value)
      inputData.categories = inputData?.categories?.map((x: any) => x.value)

      //   need to  conver  image  to  base

      const response = await API.put(`${PRODUCTS.GET_PRODUCTS}/${id}`, inputData)
      if (response.data.status) {
        return response.data?.data || {}
      } else {
      }
      return {}
    } catch (err: any) {
      toast.error(err.response.data.message)
      throw Error()
    }
  })
)

//   uplude file image
export const uploadProductFileImage = createAsyncThunk(
  'product/uploadProductFileImage',
  thunkCatchError(async ({images, id}: {images: Array<string>; id: string | number}) => {
    const response = await API.post(`${PRODUCTS.UPLOAD}`, {
      images,
      product_id: id,
    })
    //   we  need to  update  the  product
    let product = store.getState().product.product
    if (!product) {
      //  find  product  by  id
      store.dispatch(getProductById(id + ''))
    }
    if (response.data.status) {
      toast.success(response.data.message)
      const dataCreated = response.data?.data?.map((item: any) => {
        return {
          ...item,
          product_id: id,
        }
      })

      product = {
        ...product,
        product_pictures: [...dataCreated],
      }

      return product
    }

    return product
  })
)

//  bulk Discount

export const getBulkById = createAsyncThunk(
  'product/getBulkById',
  thunkCatchError(async ({id}: {id: string | number}) => {
    const response = await API.get(`${PRODUCTS.BLUK}/${id}`)

    return response.data.data
  })
)

//   remove  oneOBTION
export const removeBulkAction = createAsyncThunk(
  'product/removeBulkAction',
  thunkCatchError(async ({id, product_id}: {id: string | number; product_id: string | number}) => {
    const response = await API.delete(`${PRODUCTS.BLUK}/${id}`, {
      data: {
        product_id,
      },
    })
    if (response.data.status) {
      const {bulkDiscount} = store.getState().product
      if (!bulkDiscount) {
        store.dispatch(
          getBulkById({
            id: product_id,
          })
        )
      }
      //  we  need  to  filter  it
      const newBulkDiscount = bulkDiscount?.filter((item: any) => item.id != id)

      return newBulkDiscount
    } else {
      return []
    }
  })
)
export const updateItemBulkAction = createAsyncThunk(
  'product/updateItemBulkAction',
  thunkCatchError(async (data: any) => {
    try {
      const response = await API.put(`${PRODUCTS.BLUK}/${data.id}`, data)
      const {bulkDiscount} = store.getState().product
      if (!bulkDiscount) {
        store.dispatch(
          getBulkById({
            id: data.product_id,
          })
        )
      }
      if (response?.data?.status) {
        const newBulkDiscount = bulkDiscount.map((item: any) => {
          if (item.id == data.id) {
            return data
          }
          return item
        })

        return newBulkDiscount
      } else {
        return []
      }
    } catch (err: any) {
      toast.error(err.response.data.message)

      throw Error(err.response.data.message)
    }
  })
)

export const createItemBulkAction = createAsyncThunk(
  'product/createItemBulkAction',
  thunkCatchError(async (data: any) => {
    try {
      const response = await API.post(`${PRODUCTS.BLUK}`, data)

      const {bulkDiscount} = store.getState().product
      if (!bulkDiscount) {
        store.dispatch(
          getBulkById({
            id: data.product_id,
          })
        )
      }
      if (response?.data?.status) {
        const newBulkDiscount = bulkDiscount.concat({
          ...response.data.data,
        })

        return newBulkDiscount
      } else {
        return []
      }
    } catch (err: any) {
      toast.error(err.response.data.message)

      throw Error(err.response.data.message)
    }
  })
)

//   dealte  images
export const deleteProductImageAction = createAsyncThunk(
  'product/deleteProductImageAction',
  thunkCatchError(async ({id, product_id}: {id: string | number; product_id: string | number}) => {
    const response = await API.delete(`${PRODUCTS.DELETE_IMAGE}/${id}`, {
      data: {
        product_id,
      },
    })

    if (response?.data?.status) {
      // we need to update the product
      let product = store.getState()?.product?.product
      if (!product) {
        // find product by id
        store.dispatch(getProductById(product_id + ''))
      } else {
        const product_pictures = product?.product_pictures?.filter((item: any) => {
          return item.id != id
        })

        product = {
          ...product,
          product_pictures: product_pictures,
        }

        return product
      }
    }
    return {}
  })
)
