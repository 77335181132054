import React from 'react'

export default function HasFeature() {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_12505_109291)'>
        <path
          d='M16.9688 0H7.03125C3.148 0 0 3.148 0 7.03125V16.9688C0 20.852 3.148 24 7.03125 24H16.9688C20.852 24 24 20.852 24 16.9688V7.03125C24 3.148 20.852 0 16.9688 0Z'
          fill='#2492FF'
        />
        <path
          d='M16.1873 7.74547L10.1611 13.7717L7.81313 11.4237C7.35563 10.9662 6.61359 10.9662 6.15563 11.4237C5.69812 11.8812 5.69812 12.6233 6.15563 13.0813L9.33234 16.258C9.78984 16.7155 10.5319 16.7155 10.9898 16.258L17.8448 9.40297C18.3023 8.94547 18.3023 8.20344 17.8448 7.74547C17.3873 7.28797 16.6453 7.28797 16.1873 7.74547Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_12505_109291'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
