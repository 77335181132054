import * as Yup from 'yup'

export const bannerSchema = Yup.object().shape({
  type: Yup.string().required('typeErr'),
  title: Yup.string().required('titleErr'),
  isLink: Yup.boolean().default(false),
  image: Yup.string().when('isLink', {
    is: false,
    then: (schema) => schema.required('imageErr'),
    otherwise: (schema) => schema.notRequired(),
  }),
  video_url: Yup.string()
    .nullable() // السماح بقيمة null
    .when('isLink', {
      is: true,
      then: (schema) => schema.required('Videourlerr'),
      otherwise: (schema) => schema.notRequired(),
    }),
})
