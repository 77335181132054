import React from 'react'

export default function ConnectedWallet() {
  return (
    <svg
      width='149'
      height='139'
      viewBox='0 0 149 139'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M18.7581 21.154L90.9704 9.97511C103.131 9.52871 111.264 18.995 111.264 32.0823V93.9994C111.264 107.087 102.86 117.327 92.4926 116.871L20.4353 113.704C10.0682 113.248 1.66406 103.231 1.66406 91.3307V43.1595C1.66406 31.259 7.4521 23.3573 18.7581 21.154Z'
        fill='url(#paint0_linear_9647_97799)'
      />
      <g opacity='0.269475'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M21.5269 27.1969L88.2556 17.145C99.1903 16.7436 104.707 22.5492 104.707 34.3171V89.9921C104.707 101.76 97.1499 110.968 87.828 110.558L23.0351 110.416C13.7132 110.006 6.15625 101 6.15625 90.2987V46.9839C6.15625 36.2831 11.3608 29.178 21.5269 27.1969Z'
          fill='url(#paint1_linear_9647_97799)'
        />
      </g>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M110.953 40.2202L142.087 61.0571C146.081 63.7299 148.478 68.2187 148.478 73.0242V86.0825L112.399 61.9509L110.953 40.2202Z'
        fill='#12204C'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M8.79012 32.0589C5.42461 27.2765 13.7046 22.6494 13.7046 22.6494C13.7046 22.6494 -0.940376 27.1451 0.245649 57.9264C0.17616 58.6024 0.140625 59.2863 0.140625 59.9765V113.499C0.140625 125.399 10.7042 135.416 23.735 135.871L111.612 138.137C124.643 138.592 137.003 129.255 137.003 116.167L137.901 57.3078C137.901 44.2205 127.338 33.9805 114.307 34.4362L38.552 37.0854C26.7498 37.3217 12.5606 37.4168 8.79012 32.0589Z'
        fill='url(#paint2_linear_9647_97799)'
      />
      <g opacity='0.2'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M117.422 76.0108C117.422 76.0108 128.171 72.5707 132.877 68.5053C137.583 64.4399 137.858 58.3569 137.858 58.3569V76.0108H117.422Z'
          fill='#565656'
        />
      </g>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M138.007 72.9886C148.436 70.3766 145.202 63.5843 143.492 62.21C146.515 64.1483 148.472 68.7587 148.472 71.3057C148.472 71.3117 148.472 71.3178 148.472 71.3239C148.475 71.323 148.479 71.3221 148.483 71.3213C149.019 80.1762 148.89 89.8555 148.715 95.9344C148.614 99.4369 145.887 102.263 142.397 102.57L123.403 104.237C114.791 104.993 107.383 98.2067 107.383 89.5622C107.383 81.8279 113.363 75.4121 121.067 74.7302C126.462 74.2527 132.641 73.651 138.007 72.9886Z'
        fill='url(#paint3_linear_9647_97799)'
      />
      <ellipse
        cx='122.984'
        cy='89.8354'
        rx='7.99165'
        ry='8.0263'
        fill='url(#paint4_linear_9647_97799)'
      />
      <path
        d='M2.59375 43.5464C5.56056 51.9815 20.2402 53.5374 25.7061 53.9815C31.1721 54.4255 126.547 55.7377 136.01 47.9815'
        stroke='#B5B8C1'
        stroke-width='3'
        stroke-linecap='round'
        stroke-dasharray='9'
      />
      <path
        d='M1.69531 111.381C4.51446 119.866 19.1822 119.678 24.6395 120.218C30.0969 120.757 125.417 125.734 135.014 118.144'
        stroke='#B5B8C1'
        stroke-width='3'
        stroke-linecap='round'
        stroke-dasharray='9'
      />
      <path
        d='M120.042 2.00001C114.087 2.00021 108.366 4.31193 104.141 8.44081C99.9144 12.5714 97.5312 18.1836 97.5312 24.0455C97.5312 29.8819 99.9036 35.4698 104.111 39.5832C108.317 43.695 114.012 45.998 119.942 46C122.893 46.001 125.816 45.4339 128.544 44.3301C131.273 43.2263 133.756 41.6068 135.849 39.5618C137.942 37.5168 139.605 35.0862 140.741 32.4078C141.876 29.7298 142.461 26.8578 142.461 23.9562M120.042 2.00001C122.98 1.99781 125.891 2.56171 128.609 3.66052C131.327 4.75947 133.799 6.3725 135.884 8.40951C137.968 10.4466 139.624 12.8679 140.753 15.5361C141.883 18.2043 142.464 21.0657 142.461 23.9562M120.042 2.00001C120.042 2.00001 120.042 2.00001 120.041 2.00001L120.043 4.00001V2.00001C120.043 2.00001 120.042 2.00001 120.042 2.00001ZM142.461 23.9562L140.461 23.9545H142.461C142.461 23.9551 142.461 23.9556 142.461 23.9562Z'
        fill='url(#paint5_linear_9647_97799)'
        stroke='white'
        stroke-width='4'
      />
      <path
        d='M116.519 33.84L106.039 23.3576L109.206 20.191L116.519 27.5041L128.86 15.1626L132.03 18.3292L116.519 33.84Z'
        fill='white'
      />
      <defs>
        <linearGradient
          id='paint0_linear_9647_97799'
          x1='59.2761'
          y1='-8.49094'
          x2='33.9508'
          y2='37.8754'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#405189' />
          <stop offset='1' stop-color='#384982' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_9647_97799'
          x1='57.9602'
          y1='1.00069'
          x2='36.172'
          y2='42.0439'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#405189' />
          <stop offset='1' stop-color='#394B88' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_9647_97799'
          x1='-10.0843'
          y1='118.817'
          x2='29.3332'
          y2='118.817'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#7293FF' />
          <stop offset='1' stop-color='#405189' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_9647_97799'
          x1='148.867'
          y1='81.2326'
          x2='136.991'
          y2='81.2326'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#88A4FF' />
          <stop offset='1' stop-color='#6E8AE6' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_9647_97799'
          x1='132.751'
          y1='69.0957'
          x2='116.624'
          y2='81.6957'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='white' />
          <stop offset='1' stop-color='#405189' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_9647_97799'
          x1='103.211'
          y1='10.8857'
          x2='136.414'
          y2='38.0591'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#50CD89' />
          <stop offset='1' stop-color='#50CD89' />
        </linearGradient>
      </defs>
    </svg>
  )
}
