import {Box, Checkbox, Container, Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import UploadImg from '../customTable/UploadImg'
import ErrorMsg from '../customTable/ErrorMsg'
import RedStart from '../customTable/RedStart'
import InputType from '../customTable/InputType'
import MySelect from '../customTable/CustomSelectTest'
import SaveButton from '../customTable/SaveButton'
import Banner from '../../Api/banner'
import IBanner from '../../interface/IBanner'
import {bannerSchema} from '../../yup/BannerShcema'
import * as Yup from 'yup'
import Loading from '../customTable/LoadingForm'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import ValidationFun from '../../yup/ValidationFun'
import Label from '../customTable/Label'

export default function Form({
  rows,
  setRows,
  setOpen,
  open,
  setAdded,
  setNum,
  isLoading,
  setUsLoading,
}: any) {
  const [base64Image, setBase64Image] = React.useState('')
  const [banner, setBanner] = useState<any>({type: 1, section: 'home'})
  const [msgErr, setMsgErr] = React.useState<string>('')
  const {t} = useTranslation()
  const [errors, setErrors] = useState({})
  const toggle = ['link', 'update']
  const [isLink, setIsLink] = useState('update')
  const saveChange = (e: any) => {
    // e.preventDefault()
    ValidationFun(
      {
        ...banner,
        type: banner.type === 0 ? 'sub_banner' : 'banner',
        image: base64Image,
        sort: banner.sort ? banner.sort : 0,
        isLink: isLink === 'link',
      },
      bannerSchema,
      setErrors,
      () => {
        let newData = {
          ...banner,
          type: banner.type === 0 ? 'sub_banner' : 'banner',
          image: isLink === 'link' ? null : base64Image,
          video_url: isLink === 'link' ? banner.video_url : null,
          sort: banner.sort ? banner.sort : 0,
          media_type: isLink === 'link' ? 'video' : 'image',
        }
        if (isLink === 'link') {
          delete newData.image
        } else {
          delete newData.video_url
        }
        Banner.add(
          newData,
          (res: any) => {
            setNum((prev: any) => prev + 1)
            setBase64Image('')
            setMsgErr('')
            setOpen(false)
            setRows([{...res, status: 1}, ...rows])

            setBanner({type: 1})
            setAdded(true)
          },
          setUsLoading
        )
      }
    )
  }
  const store = useSelector((state: RootState) => state.vender)

  return (
    <Container
      lang={store.lang}
      dir={store.isRtl === 0 ? 'ltr' : 'rtl'}
      sx={{padding: '7px 24px 28px 24px'}}
      maxWidth='md'
    >
      <p style={{width: '500px'}}></p>
      <Typography sx={{fontSize: '20px', fontWeight: '600', color: 'var(--bs-body-color)'}}>
        {t('addBanner')}
      </Typography>
      <Stack mt='24px' gap='16px' direction={'row'}>
        {toggle.map((e: any) => (
          <Stack
            sx={{cursor: 'pointer'}}
            onClick={() => {
              setIsLink(e)
            }}
            gap='8px'
            alignItems={'center'}
            direction={'row'}
          >
            <Checkbox
              checked={e === isLink}
              sx={{
                color: '#405189',
                padding: '0 !important',
                '&.Mui-checked': {
                  color: '#405189',
                },
              }}
            />
            <Label text={e} />
          </Stack>
        ))}
      </Stack>

      {isLink === 'update' && open && (
        <Stack mt='25px'>
          <UploadImg
            name='image'
            errors={errors}
            open={open}
            base64Image={''}
            setBase64Image={setBase64Image}
          />
        </Stack>
      )}

      <Stack mt='24px' width={'100%'} position={'relative'} gap={'12px'}>
        <Stack direction={'row'} gap='5px'>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              color: 'var(--bs-body-color)',
              lineHeight: '16px',
            }}
          >
            {t('title')}
          </Typography>
          <RedStart />
        </Stack>
        <Box width='100%'>
          <InputType errors={errors} name={'title'} change={setBanner} value={banner} type='text' />
        </Box>
        <ErrorMsg msgErr={msgErr} name='title' />
      </Stack>
      <Stack mt='24px' width={'100%'} position={'relative'} gap={'12px'}>
        <Stack direction={'row'} gap='5px'>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              color: 'var(--bs-body-color)',
              lineHeight: '16px',
            }}
          >
            {t('type')}
          </Typography>
          {/* <RedStart /> */}
        </Stack>
        <Box width='100%'>
          <MySelect
            errors={errors}
            option={[
              {name: t('banner'), id: 1},
              {name: t('subBanner'), id: 0},
            ]}
            values={banner}
            setValues={setBanner}
            name='type'
            value={{id: 1}}
          />{' '}
        </Box>
        <ErrorMsg msgErr={msgErr} name='Type' />
      </Stack>
      <Stack mt='24px' width={'100%'} position={'relative'} gap={'12px'}>
        <Stack direction={'row'} gap='5px'>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              color: 'var(--bs-body-color)',
              lineHeight: '16px',
            }}
          >
            {t('section')}
          </Typography>
          {/* <RedStart /> */}
        </Stack>
        <Box width='100%'>
          <MySelect
            stringValue
            option={[
              // {name: t('all'), id: 'all'},
              {name: t('home'), id: 'home'},
              // {name: t('brands'), id: 'brands'},
              // {name: t('filter'), id: 'filter'},
              // {name: t('products'), id: 'products'},
              // {name: t('inside_product'), id: 'inside_product'},
              // {name: t('orders'), id: 'orders'},
              // {name: t('cart'), id: 'cart'},
            ]}
            values={banner}
            setValues={setBanner}
            name='section'
            value={{id: 'home'}}
            errors={errors}
          />{' '}
        </Box>
        <ErrorMsg msgErr={msgErr} name='Type' />
      </Stack>
      {isLink === 'link' && (
        <Stack gap='12px' mt='24px' width='100%'>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              color: 'var(--bs-body-color)',
              lineHeight: '16px',
            }}
          >
            {t('youtubeLink')}
          </Typography>
          <InputType
            errors={errors}
            name={'video_url'}
            change={setBanner}
            value={banner}
            type='text'
          />
        </Stack>
      )}
      <Stack mt='24px' width={'100%'} position={'relative'} gap={'12px'}>
        <Stack direction={'row'} gap='5px'>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '16px',
              color: 'var(--bs-body-color)',
              lineHeight: '16px',
            }}
          >
            {t('sort')}
          </Typography>
          {/* <RedStart /> */}
        </Stack>
        <Box width='100%'>
          <InputType name={'sort'} change={setBanner} value={banner} type='number' />
        </Box>
        <ErrorMsg msgErr={msgErr} name='sort' />
      </Stack>
      {/* <button onClick={saveChange}>ggg</button> */}
      <SaveButton text={t('saveChanges')} submit={saveChange} isLoading={false} setOpen={setOpen} />
      <Stack alignItems={'flex-end'}>
        <ErrorMsg msgErr={msgErr} name='' />
      </Stack>

      {isLoading && <Loading />}
    </Container>
  )
}
