import {Avatar, Button, Stack, Tooltip, Typography} from '@mui/material'
import React, {useEffect} from 'react'
import {useState} from 'react'
import LabelInput from '../../customTable/LabelInput'
import UpdateLogo from '../../../svg/UpdateLogo'
import SizeImage from '../../customTable/SizeImage'
import Image from '../../customTable/Image'
import {useSelector} from 'react-redux'

export default function UploadImage({
  base64Image,
  setBase64Image,
  text,
  setting,
  setSeeting,
  name,
  hight,
  width,
}: any) {
  const [image, setImage] = useState(base64Image || null)
  const [isWidth, setIsWidth] = useState(false)
  const [isHieght, setIsHieght] = useState(false)

  const {sizeImages} = useSelector((state: any) => state.vender)
  useEffect(() => {
    if (base64Image) {
      setImage(base64Image)
    }
  }, [base64Image])
  const handleImageChange = (event: any) => {
    const file = event.target.files[0]

    if (!file) return

    // الحصول على الحجم بالميغابايت
    const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2)

    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = () => {
      const base64 = reader.result as string
      setBase64Image(base64)
      console.log(base64, 'base64')
      setSeeting({...setting, [name]: base64})

      setImage(base64)

      // إنشاء عنصر صورة للحصول على الطول والعرض
      const img = new window.Image() as HTMLImageElement
      img.src = base64

      img.onload = () => {
        if (sizeImages[width] !== img.width) {
          setIsWidth(true)
        }
        if (sizeImages[hight] !== img.height) {
          setIsHieght(true)
        }
        console.log(`File Size: ${fileSizeInMB} MB`)
      }
    }

    reader.onerror = (error) => {
      console.error('Error reading file:', error)
    }
  }

  return (
    <>
      <Tooltip title='Only jpeg , jpg , png and svg image files are accepted'>
        <Stack
          sx={{
            cursor: 'pointer',

            border: (width && isWidth) || (isHieght && hight) ? '1px solid red' : 'none',
          }}
          justifyContent={'center'}
          alignItems={'center'}
          width={'100%'}
          height={'100%'}
        >
          <Stack component='label'>
            {image ? (
              <Stack
                width={{xs: '100%', sm: '100%'}}
                height={{xs: '100%'}}
                position={'relative'}
                className='upload-box-img'
              >
                <Image
                  sx={{
                    width: '100%',
                    height: '100%',
                    maxHeight: '250px',
                    borderRadius: '7px',
                    margin: 'auto',
                    background: 'transparent',
                    // border: '1px solid #D8D8D8',
                  }}
                  src={image}
                >
                  <UpdateLogo />
                </Image>
                {/* <img
                  style={{width: '100%', height: '100%', borderRadius: '7px'}}
                  src={image}
                  alt=''
                /> */}
                <Stack
                  display={'none'}
                  className='upload-box-img-hover'
                  width={'100%'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  height={'100%'}
                  position={'absolute'}
                >
                  <Stack borderRadius={'8px'} alignItems={'center'} padding='22px' width={'156px'}>
                    <UpdateLogo />
                  </Stack>
                </Stack>
              </Stack>
            ) : (
              <Stack p={'24px'} gap={'12px'}>
                <LabelInput text={text} />
                <Stack>
                  <UpdateLogo />
                </Stack>
                <Typography
                  sx={{
                    fontSize: '12px',
                    color: '#777777',
                    fontWeight: 300,
                    lineHeight: '16px',
                    textTransform: 'capitalize',
                  }}
                >
                  Allowed JPG, GIF or PNG. max_size_of{' '}
                  {`${sizeImages.image_size} ${sizeImages.image_type}`}
                </Typography>
              </Stack>
            )}
            <input
              hidden
              accept='.jpg, .jpeg, .png, .svg'
              onChange={handleImageChange}
              multiple
              type='file'
            />
          </Stack>
        </Stack>
      </Tooltip>
    </>
  )
}
