import {Stack} from '@mui/material'
import React, {useEffect, useState} from 'react'
import Label from '../../customTable/Label'
import PackagesApi from '../../../Api/Packages'
import {RootState} from '../../../store'
import {useSelector} from 'react-redux'
import SimpleLoading from '../../customTable/SimpleLoading'

export default function BackagesTabs({plan, setPlan, setPackageById, setIsLoadingTable}: any) {
  const [isLoading, setIsLoading] = useState(true)
  const getPackageById = (id: any) => {
    PackagesApi.getPackageCategoriesById(
      id,
      (response: any) => {
        setPackageById(response.data)
      },
      setIsLoadingTable
    )
  }
  const getPackages = () => {
    PackagesApi.getPackageCategories((res: any) => {
      setPlans([...res.data])
      if (res.data && res.data[0]) {
        setPlan(res.data[0].id)
        getPackageById(res.data[0].id)
      }
    }, setIsLoading)
  }
  const [plans, setPlans] = useState<any>([])
  const {lang, defLang} = useSelector((state: RootState) => state.vender)
  useEffect(() => {
    getPackages()
  }, [])
  return (
    <>
      {isLoading ? (
        <SimpleLoading />
      ) : (
        <Stack
          sx={{overflow: 'auto'}}
          width={'100%'}
          mt='24px'
          borderRadius={'3px'}
          p='5px'
          bgcolor={'#E3E1FF'}
          direction={'row'}
        >
          {plans.map((e: any) => {
            return (
              <Stack
                onClick={() => {
                  setPlan(e.id)
                  getPackageById(e.id)
                }}
                bgcolor={plan === e.id ? '#fff' : ''}
                p='12px'
                sx={{cursor: 'pointer'}}
                minWidth={'150px'}
                width={{xs: plans.length == 1 ? '100%' : plans.length == 2 ? '50%' : '33.3%'}}
                borderRadius={'3px'}
              >
                <Label
                  align='center'
                  text={e && e.name && (e.name[lang] || e.name[defLang])}
                  color='#554AF0'
                  fs='18px'
                  fw='600'
                />
              </Stack>
            )
          })}
        </Stack>
      )}
    </>
  )
}
