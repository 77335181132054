import * as React from 'react'
import {VarationShcema} from '../../../../../yup/VarationShcema'
import {Box, Container, IconButton, Stack, Typography} from '@mui/material'
import RedStart from '../../../../customTable/RedStart'
import InputType from '../../../../customTable/InputType'
import LabelInput from '../../../../customTable/LabelInput'
import SaveButton from '../../../../customTable/SaveButton'
import Radio from '@mui/material/Radio'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../store'
import UploadImage from '../option/UpdateImage'
import Variations from '../../../../../Api/variations'
import {useParams} from 'react-router-dom'
import MySelect from './Select'
import * as Yup from 'yup'
import Loading from '../../../../customTable/LoadingForm'
import ErrorMsg from '../../../../customTable/ErrorMsg'
import {useTranslation} from 'react-i18next'
import ValidationFun from '../../../../../yup/ValidationFun'

export default function EditVariationForm({
  open,
  setOpen,
  setItem,
  item,
  isLoadingVariations,
  setIsLoadingVariations,
}: any) {
  const {id} = useParams()
  const [options, setOptions] = React.useState([])

  const {variationImg} = useSelector((state: RootState) => state.vender)
  React.useEffect(() => {
    setBase64Image(variationImg)
  }, [open])
  const {product} = useSelector((state: any) => state.product)
  const [checked, setChecked] = React.useState(true)
  const [base64Image, setBase64Image] = React.useState('')

  const [option, setOption] = React.useState({quantity: 0})

  const getVaraition = () => {
    Variations.getSingleVariation(
      item.id,
      id,
      (res: any) => {
        setOptions(res.data.product_variations)
        setChecked(res.data.is_unlimited === 1 ? true : false)
        setBase64Image(res.data.image || variationImg)

        setOption(res.data)
      },
      setIsLoadingVariations
    )
  }

  const [selectedOptions, setSelectedOptions] = React.useState<any>({})
  const [msgErr, setMsgErr] = React.useState<string>('')
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    if (open) {
      // getProductOption()
      getVaraition()
    }
  }, [open])
  const {t} = useTranslation()
  const [errors, setErrors] = React.useState({})
  const EditVariation = async () => {
    let myArrayOfObjects: any[] = []
    let OldOption: any[] = []

    for (let key in selectedOptions) {
      myArrayOfObjects.push({
        option_detail_id: selectedOptions[key].id === -1 ? null : selectedOptions[key].id,
        option_id: selectedOptions[key].option_id,
        variation_id: selectedOptions[key].variation_id,
      })
    }
    OldOption = options.map(
      (e: any, i) =>
        (e = {
          option_detail_id: e.option_detail_id === -1 ? null : e.option_detail_id,
          option_id: e.option_id,
          variation_id: e.id,
        })
    )
    for (let i = 0; i < OldOption.length; i++) {
      let found = false
      for (let j = 0; j < myArrayOfObjects.length; j++) {
        if (OldOption[i].variation_id === myArrayOfObjects[j].variation_id) {
          found = true
          break
        }
      }
      if (!found) {
        myArrayOfObjects.push(OldOption[i])
      }
    }

    if (myArrayOfObjects.every((e) => e.option_detail_id === null)) {
      setMsgErr('error select')
    } else {
      setMsgErr('')
      let newData: Partial<typeof option> & {
        image?: string
        options: typeof myArrayOfObjects
        is_unlimited: number
      } = {
        ...option,
        image: base64Image,
        options: myArrayOfObjects,
        is_unlimited: checked ? 1 : 0,
      }

      if (!base64Image.includes('data')) {
        delete newData.image
      }

      ValidationFun(
        {
          ...option,
          product_id: id,
          is_shipping_pickup: product.is_shipping_pickup,
          options: myArrayOfObjects,
          image: base64Image,
          is_unlimited: checked ? 1 : 0,
        },
        VarationShcema,
        setErrors,
        () => {
          Variations.putVariation(
            item.id,
            id,
            newData,
            (res: any) => {
              setItem(res)

              setMsgErr('')
              setOpen(false)
            },
            setIsLoading
          )
        }
      )
      // VarationShcema.validate({
      //   ...option,
      //   product_id: id,
      //   is_shipping_pickup: product.is_shipping_pickup,
      //   options: myArrayOfObjects,
      //   image: base64Image,
      //   is_unlimited: checked ? 1 : 0,
      // })
      //   .then((value: any) => {
      //     Variations.putVariation(
      //       item.id,
      //       id,
      //       {
      //         ...option,
      //         image: base64Image.includes('data') ? base64Image : '',
      //         options: myArrayOfObjects,
      //         is_unlimited: checked ? 1 : 0,
      //       },
      //       (res: any) => {
      //         setItem(res)

      //         setMsgErr('')
      //         setOpen(false)
      //       },
      //       setIsLoading
      //     )
      //   })
      //   .catch((error: Yup.ValidationError) => {
      //     setIsLoading(false)

      //     setMsgErr(error.message)
      //   })
    }
  }
  const store = useSelector((state: RootState) => state.vender)

  const currency = localStorage.getItem('currency')

  return (
    <Container lang={store.lang} sx={{padding: '31px 24px', position: 'relative'}} maxWidth='lg'>
      <p style={{width: '900px'}}></p>

      <Stack width='100%'>
        <Stack sx={{borderBottom: '1px solid var(--bs-gray-300)', paddingBottom: '24px'}}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '19px',
              color: 'var(--bs-body-color)',
              lineHeight: '24px',
            }}
          >
            {t('variation')} #1
          </Typography>
        </Stack>
        <ErrorMsg msgErr={msgErr} name='select' />
        <ErrorMsg msgErr={msgErr} name='duplicate' />
        <Stack
          sx={{borderBottom: '1px solid var(--bs-gray-300)', paddingBottom: '24px'}}
          mt='20px'
          gap={{xs: '15px', sm: '8%'}}
          direction={{sm: 'row', xs: 'column'}}
        >
          <>
            {options.map((e, i) => (
              <Stack key={i} gap={'10px'} width={{xs: '100%', sm: '46%'}}>
                <label style={{display: 'flex', gap: '5px'}} htmlFor={'type'}>
                  <LabelInput text={(e as any).option.name.en} />

                  <RedStart />
                </label>
                <MySelect
                  option={[
                    ...(e as any).option.option_details,
                    {option_value: {en: 'any', ar: 'any'}, id: -1},
                  ]}
                  values={selectedOptions}
                  setValues={setSelectedOptions}
                  name={(e as any).option.name.en}
                  value={{
                    id: (e as any).option_detail_id ? (e as any).option_detail_id : -1,
                    option_id: (e as any).option_id,
                    variation_id: (e as any).id,
                  }}
                />
              </Stack>
            ))}
          </>
        </Stack>

        <Stack mt={'24px'} direction={{sm: 'row', xs: 'column'}}>
          <Stack gap={'28px'} direction={{sm: 'row', xs: 'column'}}>
            <UploadImage base64Image={base64Image} setBase64Image={setBase64Image} />

            <Stack gap={'15px'}>
              <Stack
                alignItems={'center'}
                // mt='40px'
                gap='20px'
                flexWrap={'wrap'}
                direction={'row'}
              >
                <Stack sx={{width: {sm: '180px', xs: '100%'}}} gap='10px'>
                  <label style={{display: 'flex', gap: '5px'}} htmlFor={'amount'}>
                    <LabelInput text={t('weight')} />
                    {product.is_shipping_pickup === 1 && <RedStart />}
                  </label>
                  <Box sx={{width: {sm: '180px', xs: '100%'}}}>
                    <InputType
                      errors={errors}
                      name={'weight'}
                      change={setOption}
                      value={option}
                      type='number'
                    />
                  </Box>
                  <ErrorMsg msgErr={msgErr} name='Weight' />
                </Stack>
              </Stack>
              <Stack
                alignItems={'center'}
                // mt='40px'
                gap='20px'
                flexWrap={'wrap'}
                direction={'row'}
              >
                <Stack sx={{width: {sm: '180px', xs: '100%'}}} gap='10px'>
                  <label style={{display: 'flex', gap: '5px'}} htmlFor={'amount'}>
                    <LabelInput text={t('width')} />

                    {product.is_shipping_pickup === 1 && <RedStart />}
                  </label>
                  <Box sx={{width: {sm: '180px', xs: '100%'}}}>
                    <InputType
                      errors={errors}
                      name={'width'}
                      change={setOption}
                      value={option}
                      type='number'
                    />
                  </Box>
                  <ErrorMsg msgErr={msgErr} name='Width' />
                </Stack>
              </Stack>
              <Stack
                alignItems={'center'}
                // mt='40px'
                gap='20px'
                flexWrap={'wrap'}
                direction={'row'}
              >
                <Stack sx={{width: {sm: '180px', xs: '100%'}}} gap='10px'>
                  <label style={{display: 'flex', gap: '5px'}} htmlFor={'amount'}>
                    <LabelInput text={t('height')} />

                    {product.is_shipping_pickup === 1 && <RedStart />}
                  </label>
                  <Box sx={{width: {sm: '180px', xs: '100%'}}}>
                    <InputType
                      errors={errors}
                      name={'height'}
                      change={setOption}
                      value={option}
                      type='number'
                    />
                  </Box>
                  <ErrorMsg msgErr={msgErr} name='Height' />
                </Stack>
              </Stack>
              <Stack
                alignItems={'center'}
                // mt='40px'
                gap='20px'
                flexWrap={'wrap'}
                direction={'row'}
              >
                <Stack sx={{width: {sm: '180px', xs: '100%'}}} gap='10px'>
                  <label style={{display: 'flex', gap: '5px'}} htmlFor={'amount'}>
                    <LabelInput text={t('length')} />

                    {product.is_shipping_pickup === 1 && <RedStart />}
                  </label>
                  <Box sx={{width: {sm: '180px', xs: '100%'}}}>
                    <InputType
                      errors={errors}
                      name={'length'}
                      change={setOption}
                      value={option}
                      type='number'
                    />
                  </Box>
                  <ErrorMsg msgErr={msgErr} name='Length' />
                </Stack>
              </Stack>
            </Stack>
          </Stack>

          <Stack
            mt={{sm: '0', xs: '20px'}}
            ml={{sm: '20px', xs: '0'}}
            p={{sm: '0 0 0 20px', xs: '0'}}
            gap='15px'
            borderLeft={{sm: '1px solid var(--bs-gray-300)', xs: 'none'}}
          >
            <Stack
              alignItems={'center'}
              // mt='40px'
              gap='20px'
              flexWrap={'wrap'}
              direction={'row'}
            >
              <Stack sx={{width: {sm: '180px', xs: '100%'}}} gap='10px'>
                <label style={{display: 'flex', gap: '5px'}} htmlFor={'amount'}>
                  <LabelInput text={t('price')} />

                  {/* <RedStart /> */}
                </label>
                <InputType
                  errors={errors}
                  name={'price'}
                  change={setOption}
                  value={option}
                  type='number'
                />

                {/* <Box position={'relative'} sx={{width: {sm: '180px', xs: '100%'}}}>
                  <InputType name={'price'} change={setOption} value={option} type='number' />
                  <Stack
                    sx={{
                      background: 'var(--bs-text-muted)',
                      top: '0',
                      right: store.isRtl === 0 ? 0 : 'auto',
                      left: store.isRtl !== 0 ? 0 : 'auto',
                      height: '100%',
                      width: '60px',
                      borderRadius: '3px',
                    }}
                    alignItems={'center'}
                    justifyContent={'center'}
                    position={'absolute'}
                  >
                    <Typography sx={{color: 'white', fontSize: '16px'}}>{currency}</Typography>
                  </Stack>
                </Box> */}
                <ErrorMsg msgErr={msgErr} name='Price' />
              </Stack>
            </Stack>
            <Stack sx={{width: {sm: '180px', xs: '100%'}}} gap='10px'>
              <label style={{display: 'flex', gap: '5px'}} htmlFor={'amount'}>
                <LabelInput text={t('costPrice')} />
                {/* <RedStart /> */}
              </label>
              <InputType name={'cost_price'} change={setOption} value={option} type='number' />

              <ErrorMsg msgErr={msgErr} name='Price' />
            </Stack>
            <Stack sx={{width: {sm: '180px', xs: '100%'}}} gap='10px'>
              <label style={{display: 'flex', gap: '5px'}} htmlFor={'amount'}>
                <LabelInput text={t('discountPrice')} />
                {/* <RedStart /> */}
              </label>
              <InputType name={'discount_amount'} change={setOption} value={option} type='number' />

              <ErrorMsg msgErr={msgErr} name='Price' />
            </Stack>
            <Stack
              alignItems={'center'}
              // mt='40px'
              gap='20px'
              flexWrap={'wrap'}
              direction={'row'}
            >
              <Stack sx={{width: {sm: '180px', xs: '100%'}}} gap='10px'>
                <label style={{display: 'flex', gap: '5px'}} htmlFor={'amount'}>
                  <LabelInput text={t('quntityInStock')} />

                  {/* <RedStart /> */}
                </label>
                <Box sx={{width: {sm: '180px', xs: '100%'}}}>
                  <InputType
                    errors={errors}
                    checked={checked}
                    name={'quantity'}
                    change={setOption}
                    value={option}
                    type='number'
                  />
                </Box>
                <ErrorMsg msgErr={msgErr} name='Quantity' />
              </Stack>
            </Stack>
            <Stack alignItems={'center'} direction={'row'}>
              <Radio
                sx={{
                  color: '#d81923',
                  '&.Mui-checked': {
                    color: '#d81923',
                  },
                }}
                checked={checked}
                onClick={() => {
                  setChecked(!checked)

                  setOption({...option, quantity: 0})
                }}
              />{' '}
              <label style={{display: 'flex', gap: '5px'}} htmlFor={'amount'}>
                <LabelInput text={t('unlimited')} />

                {/* <RedStart /> */}
              </label>
            </Stack>
          </Stack>
        </Stack>
        <SaveButton text={t('save')} submit={EditVariation} isLoading={false} setOpen={setOpen} />
      </Stack>
      <Stack alignItems={'flex-end'}>
        <ErrorMsg msgErr={msgErr} name='' />
      </Stack>
      {isLoading && <Loading />}
    </Container>
  )
}
