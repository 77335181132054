import {Button, Stack, Tooltip} from '@mui/material'
import React, {useEffect} from 'react'
import {useState} from 'react'
import ImgMsg from './ImgMsg'
import ErrorMsg from './NewMsgErr'
import SizeImage from './SizeImage'
import {useSelector} from 'react-redux'

export default function UploadImg({
  base64Image,
  setBase64Image,
  errors,
  name,
  hight,
  width,
  heightImg,
  widthImg,
}: any) {
  const [image, setImage] = useState(base64Image || '/media/avatars/img.svg')
  const [isWidth, setIsWidth] = useState(false)
  const [isHieght, setIsHieght] = useState(false)
  const {sizeImages} = useSelector((state: any) => state.vender)

  const handleImageChange = (event: any) => {
    const file = event.target.files[0]

    if (!file) return

    // الحصول على الحجم بالميغابايت
    const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2)

    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = () => {
      const base64 = reader.result as string
      setBase64Image(base64)
      setImage(base64)

      // إنشاء عنصر صورة للحصول على الطول والعرض
      const img = new Image()
      img.src = base64
      img.onload = () => {
        if (sizeImages[width] !== img.width) {
          setIsWidth(true)
        }
        if (sizeImages[hight] !== img.height) {
          setIsHieght(true)
        }

        console.log(`File Size: ${fileSizeInMB} MB`)
      }
    }

    reader.onerror = (error) => {
      console.error('Error reading file:', error)
    }
  }

  return (
    <Stack height={heightImg || 'auto'} width={widthImg || '169px'}>
      <Tooltip title='Only jpeg , jpg , png and svg image files are accepted'>
        <Stack
          width={widthImg || '150px'}
          height={heightImg || '150px'}
          padding={'0'}
          sx={{
            boxShadow: '0px 6.5px 19.5px 6.5px rgba(0, 0, 0, 0.075)',
            borderRadius: '7px',
            border: (width && isWidth) || (isHieght && hight) ? '1px solid red' : 'none',
          }}
        >
          <Button
            sx={{width: '100%', height: '100%', padding: '10px', borderRadius: '7px'}}
            component='label'
          >
            <img
              style={{width: '100%', height: heightImg || '100%', borderRadius: '8px'}}
              src={image}
              alt=''
            />
            <input
              hidden
              accept='.jpg, .jpeg, .png, .svg'
              onChange={handleImageChange}
              multiple
              type='file'
            />
          </Button>
        </Stack>
      </Tooltip>
      <SizeImage width={width} hight={hight} />
      {errors && errors[name] && errors && typeof errors[name] === 'string' && (
        <ErrorMsg msgErr={errors[name]} />
      )}
    </Stack>
  )
}
