import {Stack} from '@mui/material'
import React, {useState} from 'react'
import Product from '../../../../Api/product'
import {useParams} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {actionsProducts} from '../../../../store/vender/products/slice'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../store'
import SimpleLoading from '../../../customTable/SimpleLoading'

export default function Link({link, setLinks}: any) {
  const {product} = useSelector((state: RootState) => state.product)

  const {id} = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const deleteItem = () => {
    Product.destroyVideo(
      link.id,
      id,
      () => {
        dispatch(
          actionsProducts.setProduct({
            ...product,
            product_pictures:
              (product.product_pictures &&
                product.product_pictures.filter((e: any) => e.id !== link.id)) ||
              [],
          })
        )
      },
      setIsLoading
    )
  }
  return (
    <Stack gap='28px' alignItems={'center'} direction={'row'}>
      <Stack>
        <a
          style={{
            color: '#00c2ff',
            width: '231px', // اضبط العرض حسب الحاجة
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          href={link.picture}
          target='_blank'
        >
          {link.picture}
        </a>
      </Stack>

      {isLoading ? (
        <SimpleLoading />
      ) : (
        <Stack sx={{cursor: 'pointer'}} onClick={deleteItem}>
          {' '}
          <svg
            width='17'
            height='17'
            viewBox='0 0 17 17'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect
              x='0.367188'
              y='0.0572205'
              width='16'
              height='16'
              rx='6'
              fill='#D81923'
              fill-opacity='0.1'
            />
            <path
              d='M6.11719 6.55722V10.3072C6.11719 11.1356 6.78876 11.8072 7.61719 11.8072H9.11719C9.94561 11.8072 10.6172 11.1356 10.6172 10.3072V6.55722H6.11719Z'
              fill='#EF305E'
            />
            <path
              opacity='0.5'
              fill-rule='evenodd'
              clip-rule='evenodd'
              d='M9.11719 5.24472C9.11719 4.93406 8.86535 4.68222 8.55469 4.68222H8.17969C7.86903 4.68222 7.61719 4.93406 7.61719 5.24472H6.02344C5.86811 5.24472 5.74219 5.37064 5.74219 5.52597C5.74219 5.6813 5.86811 5.80722 6.02344 5.80722H10.7109C10.8663 5.80722 10.9922 5.6813 10.9922 5.52597C10.9922 5.37064 10.8663 5.24472 10.7109 5.24472H9.11719Z'
              fill='#EF305E'
            />
          </svg>
        </Stack>
      )}
    </Stack>
  )
}
