import React, {useState} from 'react'
import IUpLoadImg from '../interface/IUpdloadImg'
import {Stack, Tooltip} from '@mui/material'
import ImgMsg from '../modules/customTable/ImgMsg'
import ErrorMsg from '../modules/customTable/NewMsgErr'
import SizeImage from '../modules/customTable/SizeImage'
import {useSelector} from 'react-redux'
function UploadImg({
  img,
  isAllowToEdit,
  rowSlectedToEdit,
  index,
  option,
  formik,
  isActive,
  id,
  errors,
  Adderrors,
  name,
  addName,
  hight,
  width,
}: any) {
  const [isWidth, setIsWidth] = useState(false)
  const [isHieght, setIsHieght] = useState(false)
  const {sizeImages} = useSelector((state: any) => state.vender)
  return (
    <div
      style={{
        display: 'flex',
        // alignItems: 'center',
        marginTop: '0',
      }}
      className='col-lg-2 mb-3  justify-content-center  '
    >
      <Stack>
        {isActive && (
          <>
            <Tooltip title='Only jpeg , jpg , png and svg image files are accepted'>
              <Stack height={'100px'} width={'100px'}>
                <img
                  className='w-100 h-100'
                  src={typeof img === 'object' ? URL.createObjectURL(img as any) : img}
                  // alt={option.option_image[index.toString()] as string}
                  alt='user-info'
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    borderRadius: '8px',
                    border: (width && isWidth) || (isHieght && hight) ? '1px solid red' : 'none',
                  }}
                />
              </Stack>
            </Tooltip>
          </>
        )}

        {!isActive && (
          <label
            // style={{position: 'absolute', top: '0'}}
            htmlFor={`option_image_${index}`}
            // className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
          >
            <div className='image-input-wrapper w-100px h-100px shadow-sm'>
              {img ? (
                <>
                  <Tooltip title='Only jpeg , jpg , png and svg image files are accepted'>
                    <>
                      <img
                        className='w-100 h-100'
                        src={typeof img === 'object' ? URL.createObjectURL(img as any) : img}
                        // alt={option.option_image[index.toString()] as string}
                        alt='user-info'
                        style={{
                          maxWidth: '100%',
                          maxHeight: '100%',
                          borderRadius: '8px',
                          border:
                            (width && isWidth) || (isHieght && hight) ? '1px solid red' : 'none',
                        }}
                      />
                      {(errors && errors[`option_value.${name}`] && (
                        <ErrorMsg msgErr={errors[`option_value.${name}`]} />
                      )) ||
                        (Adderrors && Adderrors[`optionDetails[${index}].${name}`] && (
                          <ErrorMsg msgErr={Adderrors[`optionDetails[${index}].${name}`]} />
                        ))}
                    </>
                  </Tooltip>
                </>
              ) : (
                <Tooltip title='Only jpeg , jpg , png and svg image files are accepted'>
                  <>
                    <img src={'/media/avatars/img.svg'} alt='user-info' className='w-100 h-100' />
                    <SizeImage width={width} hight={hight} />

                    {(errors && errors[`option_value.${name}`] && (
                      <ErrorMsg msgErr={errors[`option_value.${name}`]} />
                    )) ||
                      (Adderrors && Adderrors[`optionDetails[${index}].${name}`] && (
                        <ErrorMsg msgErr={Adderrors[`optionDetails[${index}].${name}`]} />
                      ))}
                  </>
                </Tooltip>
              )}
            </div>
            {/* <i className='bi bi-pencil-fill fs-7'></i> */}
            {id ? (
              <Tooltip title='Only jpeg , jpg , png and svg image files are accepted'>
                <>
                  <input
                    id={`option_image_${index}`}
                    type='file'
                    className='w-100 h-100'
                    multiple={false}
                    style={{display: 'none'}}
                    accept='.jpg, .jpeg, .png, .svg'
                    onChange={(e) => {
                      if (e.target.files && e.target.files.length > 0) {
                        const file = e.target.files[0]

                        // التحقق من حجم الملف بالميغابايت
                        const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2)

                        const reader = new FileReader()
                        reader.onload = () => {
                          const base64 = reader.result as string

                          // استخراج الأبعاد
                          const img = new window.Image() as HTMLImageElement
                          img.src = base64

                          img.onload = () => {
                            if (sizeImages[width] !== img.width) {
                              setIsWidth(true)
                            }
                            if (sizeImages[hight] !== img.height) {
                              setIsHieght(true)
                            }
                            console.log(`File Size: ${fileSizeInMB} MB`)

                            // تحديث الحقل في formik
                            formik.setFieldValue(`optionDetails.${index}.option_image`, base64)
                          }
                        }

                        reader.onerror = (error) => {
                          console.error('Error reading file:', error)
                          formik.setFieldError(
                            `optionDetails.${index}.option_image`,
                            'Error reading file'
                          )
                        }

                        reader.readAsDataURL(file)
                      }
                    }}
                    onBlur={(e) => {
                      formik.setFieldTouched(`options.${index}.option_image`, true)
                    }}
                  />{' '}
                  {(errors && errors[`${name}`] && <ErrorMsg msgErr={errors[`${name}`]} />) ||
                    (Adderrors && Adderrors[`optionDetails[${index}].${name}`] && (
                      <ErrorMsg msgErr={Adderrors[`optionDetails[${index}].${name}`]} />
                    ))}
                </>
              </Tooltip>
            ) : (
              <Tooltip title='Only jpeg , jpg , png and svg image files are accepted'>
                <>
                  <input
                    id={`option_image_${index}`}
                    type='file'
                    className='w-100 h-100'
                    multiple={false}
                    style={{display: 'none'}}
                    accept='.jpg, .jpeg, .png, .svg'
                    onChange={(e) => {
                      if (e.target.files && e.target.files.length > 0) {
                        const file = e.target.files[0]

                        // التحقق من حجم الملف بالميغابايت
                        const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2)

                        const reader = new FileReader()
                        reader.onloadend = () => {
                          const base64String = reader.result as string

                          // استخراج الأبعاد
                          const img = new window.Image() as HTMLImageElement
                          img.src = base64String

                          img.onload = () => {
                            if (sizeImages[width] !== img.width) {
                              setIsWidth(true)
                            }
                            if (sizeImages[hight] !== img.height) {
                              setIsHieght(true)
                            }
                            console.log(`File Size: ${fileSizeInMB} MB`)

                            // تحديث الحقل في formik
                            formik.setFieldValue(
                              `optionDetails.${index}.option_image`,
                              base64String
                            )
                          }
                        }

                        reader.onerror = (error) => {
                          console.error('Error reading file:', error)
                          formik.setFieldError(
                            `optionDetails.${index}.option_image`,
                            'Error reading file'
                          )
                        }

                        reader.readAsDataURL(file) // تحويل الملف إلى Base64
                      }
                    }}
                    onBlur={(e) => {
                      formik.setFieldTouched(`options.${index}.option_image`, true)
                    }}
                  />
                </>
              </Tooltip>
            )}
          </label>
        )}
      </Stack>
    </div>
  )
}

export default UploadImg
