import {Stack} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import MainBtn from '../customTable/MainBtn'

export default function Tabs({active, setActive, data}: any) {
  const {t} = useTranslation()
  const store = useSelector((state: RootState) => state.vender)
  console.log(data, 'datadata')

  const tabs = [
    {name: t('Fashion & Jewelry'), isRead: true},
    {name: t('Fashion & Jewelry'), isRead: true},
    {name: t('Fashion & Jewelry'), isRead: true},
    {name: t('Fashion & Jewelry'), isRead: true},
    {name: t('Fashion & Jewelry'), isRead: true},
    {name: t('Fashion & Jewelry'), isRead: true},
    {name: t('Fashion & Jewelry'), isRead: true},
  ]
  return (
    <Stack sx={{overflowX: 'auto'}} p={'60px 0 20px 0'} gap='40px' direction={'row'}>
      {data.map((e: any) => (
        <Stack width={'max-content'}>
          {
            <MainBtn
              without={e.id === active ? false : true}
              handelSubmit={() => setActive(e.id)}
              width={'max-content'}
              text={e.name}
            />
          }
        </Stack>
      ))}
      <Stack width={'20px'}></Stack>
    </Stack>
  )
}
