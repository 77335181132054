import {toast} from 'react-toastify'
import Apiservices from '../../lib/ApiServices'
import IBanner from '../interface/IBanner'
import ApisMethods from './apisMethods'

const Temps = {
  //*
  getCategories: (callBack: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/templates/template-categories`, callBack, setIsLoading)
  },
  getTemplates: (id: any, callBack: any, setIsLoading: any) => {
    ApisMethods().get(
      `/vendor/templates/template-categories/${id}/templates`,
      callBack,
      setIsLoading
    )
  },

  change: (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/templates/change-template`, data, callback, setIsLoading)
  },
}
export default Temps
