import React, {useState} from 'react'
import {Stack} from '@mui/material'

import {RootState} from '../../../store'
import {useSelector} from 'react-redux'
import SimpleLoading from '../../customTable/SimpleLoading'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useTranslation} from 'react-i18next'
import PopUp from '../../customTable/PopUp'
import HasNotFeature from '../../../svg/HasNotFeature'
import HasFeature from '../../../svg/HasFeature'
import Percentage from '../../../svg/Percentage'
import Label from '../../customTable/Label'
import NumPackage from '../../../svg/NumPackage'
import Callender from '../../../svg/Callender'

export default function Row({item, discount, index, freeTrialDuration}: any) {
  const [open, setOpen] = React.useState(false)
  const [editItem, setEditItem] = useState(item)
  const handelOpen = () => {
    setOpen(true)
  }

  const [isDeleted, setDeleted] = useState(true)

  const {lang, defLang} = useSelector((state: RootState) => state.vender)
  const {t} = useTranslation()

  return (
    <>
      {isDeleted && (
        <>
          {item.name !== 'name' &&
            item.name !== 'is_most_popular' &&
            item.name !== 'is_discount' &&
            item.name !== 'discount_type' &&
            item.name !== 'free_trial_duration' &&
            item.name !== 'discount_amount' && (
              <tr className='fw-bold text-muted '>
                {item.name !== 'name' && (
                  <td style={{textAlign: 'start'}}>
                    {(item.name && (item.name[lang] || item.name[defLang])) || t(item.name)}
                  </td>
                )}
                {Object.entries(item).map(([key, value]: any, i) => {
                  return (
                    <>
                      {key !== 'name' && key !== 'color' && (
                        <td style={{textAlign: 'center'}}>
                          {/* تأكد من تمرير عنصر نصي أو قيمة واحدة فقط */}
                          {key !== 'name' && key !== 'color' ? (
                            <a className='text-dark fw-bold text-hover-primary fs-6'>
                              {typeof value === 'object' ? (
                                value.is_checke_box == 1 && value.hasFeature === true ? (
                                  <HasFeature />
                                ) : value.hasFeature === true ? (
                                  value.amount ? (
                                    value.amount
                                  ) : (
                                    'unlimit'
                                  )
                                ) : (
                                  <HasNotFeature />
                                )
                              ) : (
                                <Stack gap='8px'>
                                  {value.toString()}
                                  <Stack>
                                    {index == 0 && discount[key] && (
                                      <Stack
                                        gap='5px'
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                        direction={'row'}
                                      >
                                        <Percentage />
                                        <Stack mt='2px'>
                                          {' '}
                                          <Label
                                            fs='15px'
                                            fw='600'
                                            color='#F44336'
                                            text={`${discount[key].split(' ')[0]}`}
                                          />
                                        </Stack>
                                      </Stack>
                                    )}

                                    {index == 1 && freeTrialDuration[key] && (
                                      <Stack
                                        gap='5px'
                                        alignItems={'center'}
                                        justifyContent={'center'}
                                        direction={'row'}
                                      >
                                        <Callender />
                                        <Stack mt='2px'>{freeTrialDuration[key]}</Stack>
                                      </Stack>
                                    )}
                                  </Stack>
                                </Stack>
                              )}
                            </a>
                          ) : null}
                        </td>
                      )}
                    </>
                  )
                })}
              </tr>
            )}
        </>
      )}
    </>
  )
}
