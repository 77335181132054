import {TabsLanguages} from '../../addCtogory/TabsLanguages'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import makeAnimated from 'react-select/animated'
import CloseIcon from '@mui/icons-material/Close'

import * as Yup from 'yup'
import {useEffect} from 'react'
import {useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {Language} from '../../../../types'
import {isValidLanguages} from '../../../../utils/isAllow'
import {InitialValues} from '../validationSchema'
import {FormikProps} from 'formik'
import ScrollToTop from '../../../../componet/scrollTop'
import {Generalschema} from '../../../../yup/ProductsShcema'
import ErrorMsg from '../../../customTable/ErrorMsg'
import {IconButton, Stack, Typography} from '@mui/material'
import {useTranslation} from 'react-i18next'
import TabsWithFlag from '../../../customTable/LangTabsWithFlag'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../store'
import Editor from '../../../customTable/Editor'
import {useParams} from 'react-router-dom'
import Snackbar from '@mui/material/Snackbar'
import * as React from 'react'

import MuiAlert, {AlertProps} from '@mui/material/Alert'
import MainBtn from '../../../customTable/MainBtn'
import ValidationFun from '../../../../yup/ValidationFun'
import OldInput from '../../../customTable/OldInput'
import MasgsError from '../../../customTable/MasgsError'
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})
const animatedComponents = makeAnimated()
type Props = {
  formik: FormikProps<InitialValues> | any
  activeLang: string
  setActiveLang: React.Dispatch<React.SetStateAction<Language>>
  setActiveKey: React.Dispatch<React.SetStateAction<string>>
  setActive: React.Dispatch<React.SetStateAction<number>>
}

export const General = (props: any) => {
  const intl = useIntl()

  const {formik, activeLang, setActiveLang, setActiveKey, setActive, setErrors, errors} = props
  const {id} = useParams()
  const isAllowNext = useMemo(() => {
    const {
      name,
      description,
      meta_title,
      meta_description,
      meta_keywords,
      product_tags,
      permalink,
    } = formik.errors

    const isValid =
      isValidLanguages(name) &&
      isValidLanguages(description) &&
      isValidLanguages(meta_title) &&
      isValidLanguages(meta_description) &&
      isValidLanguages(meta_keywords) &&
      isValidLanguages(product_tags) &&
      isValidLanguages(permalink)

    return isValid
  }, [
    formik.errors.name,
    formik.errors.description,
    formik.errors.meta_title,
    formik.errors.meta_description,
    formik.errors.meta_keywords,
    formik.errors.product_tags,
    formik.errors.permalink,
  ])
  const [languages, setlanguages] = useState(['en'])
  const store = useSelector((state: RootState) => state.vender)
  useEffect(() => {
    setlanguages(store.storeLangs)
  }, [store.storeLangs])
  const handelNext = () => {
    if (id) {
      ValidationFun(formik.values, Generalschema(languages), setErrors, () => {
        setActive(7)
        setActiveKey('Advanced')
      })
    } else {
      ValidationFun(formik.values, Generalschema(languages), setErrors, () => {
        setActive(2)
        setActiveKey('Advanced')
      })
    }
  }
  const [msgErr, setMsgErr] = useState('')
  const {t} = useTranslation()
  const [langs, setLangs] = useState([])
  const [open, setOpen] = React.useState(false)
  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }
  const getLangs = async () => {
    setLangs(store.langs)
  }
  useEffect(() => {
    if (store.defLang) {
      setActiveLang(
        store.langs.some((e: any) => e.code === store.defLang) ? store.defLang : store.langs[0].code
      )
    }
    getLangs()
  }, [store.langs])
  const [tag, setTag] = useState('')
  const [tagProduct, setTagProduct] = useState('')
  const addTag = () => {
    if (!tag) {
      return
    }

    if (!formik.values.meta_keywords[`${activeLang}`]) {
      formik.setFieldValue(`meta_keywords[${activeLang}]`, [tag])
      setTag('')
    } else if (
      formik.values.meta_keywords[`${activeLang}`] &&
      !formik.values.meta_keywords[`${activeLang}`].includes(tag)
    ) {
      formik.setFieldValue(`meta_keywords[${activeLang}]`, [
        ...formik.values.meta_keywords[`${activeLang}`],
        tag,
      ])
      setTag('')
    } else {
      handleClick()
    }
  }

  const addProductTag = () => {
    if (!tagProduct) {
      return
    }
    if (!formik.values.product_tags[`${activeLang}`]) {
      formik.setFieldValue(`product_tags[${activeLang}]`, [tagProduct])
      setTagProduct('')
    } else if (
      formik.values.product_tags[`${activeLang}`] &&
      !formik.values.product_tags[`${activeLang}`].includes(tagProduct)
    ) {
      formik.setFieldValue(`product_tags[${activeLang}]`, [
        ...formik.values.product_tags[`${activeLang}`],
        tagProduct,
      ])
      setTagProduct('')
    } else {
      handleClick()
    }
  }
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      addTag()
    }
  }
  const handleKeyDownProduct = (event: any) => {
    if (event.key === 'Enter') {
      addProductTag()
    }
  }
  return (
    <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
      {/* <TabsLanguages name='addProduct' activeLang={activeLang} setActiveLang={setActiveLang}> */}
      <Stack position={'relative'}>
        <TabsWithFlag
          languages={langs}
          msgErr={errors && Object.keys(errors)[0]}
          activeLang={activeLang}
          setActiveLang={setActiveLang}
        />
      </Stack>
      <>
        {langs.map((e: any, i) => (
          <>
            {e.code === activeLang && (
              <Stack sx={{background: 'var(--bs-app-bg-color)'}}>
                <div className='card card-flush py-4 mb-4'>
                  {/* //<!--begin::Card header--> */}
                  <div className='card-header'>
                    <div className='card-title'>
                      <h2>{t('general')}</h2>
                    </div>
                  </div>
                  {/* //<!--end::Card header--> */}
                  {/* //<!--begin::Card body--> */}
                  <div className='card-body pt-0'>
                    {/* //<!--begin::Input group--> */}

                    <div className='mb-10 fv-row'>
                      {/* //<!--begin::Label--> */}
                      <label className='required form-label'>{t('productName')}</label>
                      <OldInput
                        onBlur={() => {
                          formik.setFieldTouched(`name[${activeLang}]`, true)
                          if (id) {
                            if (!formik.values.permalink[activeLang]) {
                              formik.setFieldValue(
                                `permalink[${activeLang}]`,
                                formik.values.name[activeLang]
                                  .slice(0, 50)
                                  .trim()
                                  .replace(/\s/g, '-')
                              )
                            }
                          } else {
                            formik.setFieldValue(
                              `permalink[${activeLang}]`,
                              formik.values.name[activeLang].slice(0, 50).trim().replace(/\s/g, '-')
                            )
                          }
                        }}
                        errors={errors}
                        child={activeLang}
                        name={'name'}
                        formik={formik}
                      />
                    </div>

                    <div>
                      {/* //<!--begin::Label--> */}
                      <label className='form-label required'>{t('description')}</label>
                      <Editor
                        errors={errors}
                        child={'description'}
                        formik={formik}
                        name={activeLang}
                      />

                      <div style={{marginTop: '30px'}}></div>

                      {/* //<!--end::Editor--> */}
                      {/* //<!--begin::Description--> */}
                      <div className='mb-10 fv-row'>
                        {/* //<!--begin::Label--> */}
                        <label className='required form-label'>{t('permalink')}</label>
                        <OldInput
                          max={50}
                          child={activeLang}
                          errors={errors}
                          name={'permalink'}
                          formik={formik}
                        />
                      </div>
                    </div>
                    {/* //<!--end::Input group--> */}
                  </div>
                  {/* //<!--end::Card header--> */}
                </div>
                {/* //<!--end::General options--> */}
                {/* //<!--begin::Meta options--> */}
                <div style={{marginTop: '20px'}} className='card card-flush py-4'>
                  {/* //<!--begin::Card header--> */}
                  <div className='card-header'>
                    <div className='card-title'>
                      <h2> {t('seo')}</h2>
                    </div>
                  </div>
                  {/* //<!--end::Card header--> */}
                  {/* //<!--begin::Card body--> */}
                  <div className='card-body pt-0'>
                    {/* //<!--begin::Input group--> */}
                    <div className='mb-10'>
                      {/* //<!--begin::Label--> */}
                      <label className='form-label'> {t('keyword')}</label>
                      {/* //<!--end::Label--> */}
                      {/* //<!--begin::Input--> */}
                      <>
                        <Stack position={'relative'}>
                          <input
                            type='text'
                            value={tag}
                            onChange={(e) => setTag(e.target.value)}
                            className='form-control form-control-lg'
                            onKeyDown={handleKeyDown}
                          />
                          <Stack
                            position={'absolute'}
                            height={'100%'}
                            width={'43px'}
                            right={store.isRtl === 0 ? 0 : 'auto'}
                            left={store.isRtl !== 0 ? 0 : 'auto'}
                            borderRadius={store.isRtl === 0 ? '0 8px 8px 0' : '8px 0 0 8px'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            sx={{
                              background: !tag ? '#afb2c1' : 'var(--main-color)',
                              color: 'white',
                              cursor: 'pointer',
                            }}
                            onClick={addTag}
                          >
                            <Typography sx={{fontSize: '20px'}}>+</Typography>
                          </Stack>
                        </Stack>
                        <Stack flexWrap={'wrap'} m='20px 0' gap='15px' direction={'row'}>
                          {formik.values.meta_keywords &&
                            formik.values.meta_keywords[`${activeLang}`] &&
                            typeof formik.values.meta_keywords[`${activeLang}`] !== 'string' &&
                            formik.values.meta_keywords[`${activeLang}`] &&
                            formik.values.meta_keywords[`${activeLang}`].map((e: any, i: any) => (
                              <Stack
                                gap='15px'
                                minWidth={'60px'}
                                p='3px 8px'
                                borderRadius={'6px'}
                                sx={{background: '#f5f8fa'}}
                                direction={'row'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                              >
                                <Typography sx={{color: '#181c32'}}>{e}</Typography>
                                <Typography
                                  onClick={() => {
                                    formik.setFieldValue(
                                      `meta_keywords[${activeLang}]`,
                                      formik.values.meta_keywords[`${activeLang}`].filter(
                                        (a: any) => a !== e
                                      )
                                    )
                                  }}
                                  sx={{color: '#181c32', cursor: 'pointer'}}
                                >
                                  {' '}
                                  <svg
                                    height='14'
                                    width='14'
                                    viewBox='0 0 20 20'
                                    aria-hidden='true'
                                    focusable='false'
                                    className='css-tj5bde-Svg'
                                  >
                                    <path d='M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z'></path>
                                  </svg>
                                </Typography>
                              </Stack>
                            ))}
                          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity='warning' sx={{width: '100%'}}>
                              {t('msgtag')}{' '}
                            </Alert>
                          </Snackbar>
                        </Stack>
                      </>
                      <ErrorMsg msgErr={msgErr} name='Meta keywords' />
                    </div>
                    <div className='mb-10'>
                      {/* //<!--begin::Label--> */}
                      <label className='form-label'>{t('metaTitle')}</label>
                      {/* //<!--end::Label--> */}
                      {/* //<!--begin::Input--> */}
                      <OldInput
                        formik={formik}
                        errors={errors}
                        name={'meta_title'}
                        child={activeLang}
                      />
                      {/* <input
                        type='text'
                        {...formik.getFieldProps(`meta_title[${activeLang}]`)}
                        className='form-control mb-2'
                      /> */}
                      {/* <ErrorMsg msgErr={msgErr} name='Meta title' /> */}
                    </div>

                    <div className='mb-10'>
                      {/* //<!--begin::Label--> */}
                      <label className=' form-label'>{t('metaDescription')} </label>
                      <OldInput
                        formik={formik}
                        errors={errors}
                        name={'meta_description'}
                        child={activeLang}
                      />

                      {/* <input
                        type='text'
                        {...formik.getFieldProps(`meta_description[${activeLang}]`)}
                        className='form-control mb-2'
                      />
                      <ErrorMsg msgErr={msgErr} name='Meta_description' /> */}
                    </div>

                    {/* <div>
                      <label className='form-label'>{t('productTag')} </label>
                     
                      <>
                        <Stack position={'relative'}>
                          <input
                            type='text'
                            value={tagProduct}
                            onChange={(e) => setTagProduct(e.target.value)}
                            className='form-control form-control-lg '
                            onKeyDown={handleKeyDownProduct}
                          />
                          <Stack
                            position={'absolute'}
                            height={'100%'}
                            width={'43px'}
                            right={store.isRtl === 0 ? 0 : 'auto'}
                            left={store.isRtl !== 0 ? 0 : 'auto'}
                            borderRadius={store.isRtl === 0 ? '0 8px 8px 0' : '8px 0 0 8px'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            sx={{
                              background: !tagProduct ? '#afb2c1' : 'var(--main-color)',
                              color: 'white',
                              cursor: 'pointer',
                            }}
                            onClick={addProductTag}
                          >
                            <Typography sx={{fontSize: '20px'}}>+</Typography>
                          </Stack>
                        </Stack>
                        <Stack flexWrap={'wrap'} m='20px 0' gap='15px' direction={'row'}>
                          {formik.values.product_tags &&
                            formik.values.product_tags[`${activeLang}`] &&
                            typeof formik.values.product_tags[`${activeLang}`] !== 'string' &&
                            formik.values.product_tags[`${activeLang}`] &&
                            formik.values.product_tags[`${activeLang}`].map((e: any, i: any) => (
                              <Stack
                                gap='15px'
                                minWidth={'60px'}
                                p='3px 8px'
                                borderRadius={'6px'}
                                sx={{background: '#f5f8fa'}}
                                direction={'row'}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                              >
                                <Typography sx={{color: '#181c32'}}>{e}</Typography>
                                <Typography
                                  onClick={() => {
                                    formik.setFieldValue(
                                      `product_tags[${activeLang}]`,
                                      formik.values.product_tags[`${activeLang}`].filter(
                                        (a: any) => a !== e
                                      )
                                    )
                                  }}
                                  sx={{color: '#181c32', cursor: 'pointer'}}
                                >
                                  {' '}
                                  <svg
                                    height='14'
                                    width='14'
                                    viewBox='0 0 20 20'
                                    aria-hidden='true'
                                    focusable='false'
                                    className='css-tj5bde-Svg'
                                  >
                                    <path d='M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z'></path>
                                  </svg>
                                </Typography>
                              </Stack>
                            ))}
                          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                            <Alert onClose={handleClose} severity='warning' sx={{width: '100%'}}>
                              {t('msgtag')}{' '}
                            </Alert>
                          </Snackbar>
                        </Stack>
                      </>

                      <ErrorMsg msgErr={msgErr} name='product_tags' />
                    </div> */}
                    {/* //<!--end::Input group--> */}
                  </div>
                  {/* //<!--end::Card header--> */}
                </div>
              </Stack>
            )}
          </>
        ))}
      </>

      {/* </TabsLanguages> */}
      <div style={{gap: '15px'}} className='d-flex justify-content-end'>
        {errors && <MasgsError errors={errors} />}

        <MainBtn
          width='auto'
          handelSubmit={handelNext}
          isLoading={formik.isSubmitting}
          text='next'
        />
      </div>
      <div className='d-flex justify-content-end'>
        <ErrorMsg msgErr={msgErr} name='' />
      </div>

      <ScrollToTop />
    </div>
  )
}
