import {Box, Stack} from '@mui/material'
import React, {useState} from 'react'
import Label from '../customTable/Label'
import Image from '../customTable/Image'
import MainBtn from '../customTable/MainBtn'
import PopUp from '../customTable/PopUp'
import Temps from '../../Api/Temps'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'

export default function TemplateCard({current, i, data, setCurrent}: any) {
  console.log(data, 'temps')
  const store = useSelector((state: RootState) => state.vender)

  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  return (
    <>
      <Stack
        p='16px'
        gap='18px'
        width={{lg: '195px', sm: '40%', xs: '80%', md: 'calc(50% - 12px)'}}
        borderRadius={'8px'}
        bgcolor={'#F8F8F8'}
      >
        <Stack height={'20px'}>
          <Label fw='500' fs='14px' text={data.name} />
        </Stack>
        <Box sx={{width: '100%', height: '148px'}}>
          <Image
            sx={{height: '100%', width: '100%', borderRadius: '8px'}}
            src={store.isRtl === 1 ? data.rtl_image : data.rtl_image}
          />
        </Box>
        {i === current || data.is_choice ? (
          <Stack justifyContent={'center'}>
            <MainBtn handelSubmit={() => {}} text='currentTemplate' without />
          </Stack>
        ) : (
          <Stack gap='16px' justifyContent={'center'} direction={'row'}>
            <MainBtn
              minWidth='0px'
              width='73px'
              handelSubmit={() => {
                setOpen(true)
              }}
              text='select'
            />
            <MainBtn
              minWidth='0px'
              width='73px'
              handelSubmit={() =>
                window.open(store.isRtl === 1 ? data.rtl_link : data.ltr_link, '_blank')
              }
              text='preview'
              without
            />
          </Stack>
        )}
        <PopUp width='sm' open={open} setOpen={setOpen}>
          <Stack
            alignItems={'center'}
            borderRadius={'8px'}
            bgcolor={'#fff'}
            p={{xs: '24px', md: '40px'}}
          >
            <Stack width={{md: '419px'}} alignItems={'center'} gap='16px'>
              <Label align='center' fs='20px' text='yourAreGoingChangeYourStoreTemplate' />
              <Stack gap='5px' direction={'row'}>
                <Label
                  align='center'
                  color='#B5B5C3'
                  fs='14px'
                  text='ChangingTheTemplateWillIncurAnAdditionalFeeOf'
                />
              </Stack>

              <Stack mt='24px' gap='24px' justifyContent={'center'} direction={'row'}>
                <MainBtn
                  isLoading={isLoading}
                  width='fit-content'
                  handelSubmit={() => {
                    Temps.change(
                      {template_id: data.id},
                      () => {
                        setCurrent(data.id)
                        setOpen(false)
                      },
                      setIsLoading
                    )
                  }}
                  text='confirm'
                />
                <MainBtn
                  width='fit-content'
                  handelSubmit={() => {
                    setOpen(false)
                  }}
                  text='cancel'
                  without
                />
              </Stack>
            </Stack>
          </Stack>
        </PopUp>
      </Stack>
    </>
  )
}
