import React from 'react'

export default function ErrPacakge() {
  return (
    <svg
      width='120'
      height='120'
      viewBox='0 0 120 120'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M60 109.999C87.5 109.999 110 87.499 110 59.999C110 32.499 87.5 9.99902 60 9.99902C32.5 9.99902 10 32.499 10 59.999C10 87.499 32.5 109.999 60 109.999Z'
        fill='#405189'
      />
      <path
        d='M60 39.999V64.999'
        stroke='white'
        stroke-width='7'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M59.9688 80.001H60.0137'
        stroke='white'
        stroke-width='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
