import React, {useState, useEffect} from 'react'
import {Stack} from '@mui/material'
import RowBtn from './RowBtn'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'

export default function RowBtns({
  colors,
  prices,
  duration,
  type,
  name,
  paidId,
  freeId,
  paidDurationId,
}: any) {
  const [num, setNum] = useState(-1)
  const {sizeImages} = useSelector((state: RootState) => state.vender)

  // Effect to determine the current subscription type and set `num`
  useEffect(() => {
    if (sizeImages && sizeImages.subscription) {
      const {subscription} = sizeImages
      const {package_type} = subscription

      let foundIndex = -1
      if (package_type === 'free') {
        // Find the matching `freeId`
        for (const key in freeId) {
          if (freeId[key] === subscription.package_free_id) {
            foundIndex = Number(key) // Set the index based on the matching key
          }
        }
      } else if (package_type === 'paid') {
        // Find the matching `paidId`
        for (const key in paidId) {
          if (paidId[key] === subscription.package_paid_id) {
            foundIndex = Number(key) // Set the index based on the matching key
          }
        }
      } else if (package_type === 'paid_duration') {
        // Find the matching `paidDurationId`
        for (const key in paidDurationId) {
          if (paidDurationId[key] === subscription.package_paid_duration_id) {
            foundIndex = Number(key) // Set the index based on the matching key
          }
        }
      }

      // Set `num` only if a matching index is found
      if (foundIndex !== -1) {
        setNum(foundIndex)
      }
    }
  }, [sizeImages, paidId, freeId, paidDurationId])

  return (
    <tr className='fw-bold text-muted'>
      <td></td>
      {Object.entries(colors).map(([key, value], i) => {
        return (
          <td key={key}>
            <Stack alignItems={'center'}>
              <RowBtn
                setNum={setNum}
                name={name}
                type={type}
                duration={duration}
                prices={prices}
                value={value}
                myKey={key}
                index={
                  type[key] === 'paid_duration'
                    ? paidDurationId[key]
                    : type[key] === 'paid'
                    ? paidId[key]
                    : freeId[key]
                }
                i={num}
                text={num < i ? 'upgrade' : num > i ? 'downgrade' : 'currentPlan'}
              />
            </Stack>
          </td>
        )
      })}
    </tr>
  )
}
