import {Button, Stack} from '@mui/material'
import React, {useState} from 'react'
import MainBtn from '../../customTable/MainBtn'
import PopUp from '../../customTable/PopUp'
import Label from '../../customTable/Label'
import PayForm from './PayForm'
import PackagesApi from '../../../Api/Packages'
import Varefacation from '../Varefacation'
import Alert from '@mui/material/Alert'
import Snackbar, {SnackbarCloseReason} from '@mui/material/Snackbar'
import {useTranslation} from 'react-i18next'
import ErrPacakge from '../../../svg/ErrPacakge'

export default function RowBtn({
  index,
  prices,
  value,
  duration,
  isRenew,
  name,
  type,
  i,
  text,
  myKey,
  setNum,
}: any) {
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isVaravacation, setIsVaravacation] = useState(false)
  const [signature, setSignature] = useState('')
  const [isLoadingBtn, setIsLoadingBtn] = useState(false)
  const [openAlert, setOpenAlert] = React.useState(false)
  const [msgErr, setMsgErr] = useState('')
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpenAlert(false)
  }

  const submit = () => {
    if (isRenew) {
      PackagesApi.renew((res: any) => {
        setIsVaravacation(true)
      }, setIsLoading)
    } else {
      PackagesApi.upgrade(
        {
          plan_id: index,
          plan_type: type[myKey],
        },
        (res: any) => {
          if (res.msgErr) {
            setMsgErr(res.msgErr)
          } else {
            setSignature(res.signature)
            setOpen(false)
            setIsVaravacation(true)
          }
        },
        setIsLoading,
        true
      )
    }
  }
  const {t} = useTranslation()
  return (
    <Stack>
      <Snackbar open={openAlert} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity='info' variant='filled' sx={{width: '100%'}}>
          {t('youAreSubscribedToThisPackage')}
        </Alert>
      </Snackbar>
      <MainBtn
        handelSubmit={() => {
          if (text === 'currentPlan') {
            setOpenAlert(true)
          } else {
            setOpen(true)
          }
        }}
        br='8px'
        minWidth='101px'
        bgcolor={value}
        text={text}
      />
      <PopUp isLoading={isLoading} width='sm' open={open} setOpen={setOpen}>
        {msgErr == '' ? (
          <PayForm
            isLoading={isLoading}
            submit={submit}
            duration={duration[myKey]}
            price={prices[myKey]}
            name={name[myKey]}
            type={type[myKey]}
          />
        ) : (
          <Stack p='45px 66px 55px 66px' gap='24px' alignItems={'center'}>
            <ErrPacakge />
            <Label fs='18px' align='center' text={msgErr} />
          </Stack>
        )}
      </PopUp>
      <PopUp isLoading={isLoadingBtn} width='xs' open={isVaravacation}>
        {isVaravacation && (
          <Varefacation
            setIsLoading={setIsLoadingBtn}
            isLoading={isLoadingBtn}
            type={type[myKey]}
            signature={signature}
            isRenew={isRenew}
            price={prices[myKey]}
            setNum={setNum}
            name={name[myKey]}
            id={index}
            setOpen={setIsVaravacation}
            myKey={myKey}
            open={isVaravacation}
          />
        )}
      </PopUp>
    </Stack>
  )
}
