import {Stack} from '@mui/material'
import React, {useState} from 'react'
import InputType from '../../customTable/InputType'
import LabelInput from '../../customTable/LabelInput'
import MainBtn from '../../customTable/MainBtn'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import General from '../../../Api/SettingGeneral'
import {EditWiteLabelSchema} from '../../../yup/SettingSchema'
import ValidationFun from '../../../yup/ValidationFun'

export default function EditFoter() {
  const [errors, setErrors] = useState({})
  const [data, setData] = useState({})
  const [open, setOpen] = useState(true)
  const store = useSelector((state: RootState) => state.vender)
  const [isLoading, setIsLoading] = useState(false)

  return (
    <Stack>
      <Stack gap='10px'>
        <label style={{display: 'flex', gap: '5px'}}>
          <LabelInput text={'whiteLabel'} />
        </label>
        <Stack position={'relative'}>
          <InputType
            checked={open}
            placeholder='whiteLabel'
            errors={errors}
            name={'white_label'}
            change={setData}
            value={data}
            type='text'
          />
          <Stack
            right={store.isRtl == 0 ? '5px' : 'auto'}
            left={store.isRtl == 1 ? '5px' : 'auto'}
            m={'5px 0px'}
            position={'absolute'}
          >
            <MainBtn
              isLoading={isLoading}
              handelSubmit={() => {
                if (open) {
                  setOpen(false)
                } else {
                  ValidationFun(data, EditWiteLabelSchema, setErrors, () => {
                    General.editWiteLabel(
                      data,
                      () => {
                        setOpen(true)
                      },
                      setIsLoading
                    )
                  })
                }
              }}
              text={open ? 'edit' : 'save'}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
