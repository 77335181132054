import React from 'react'

export default function Callender() {
  return (
    <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.33594 2.30225V4.30225'
        stroke='#28A745'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M10.6641 2.30225V4.30225'
        stroke='#28A745'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M2.33594 7.02881H13.6693'
        stroke='#28A745'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M12.0026 16.3021C13.4754 16.3021 14.6693 15.1082 14.6693 13.6354C14.6693 12.1627 13.4754 10.9688 12.0026 10.9688C10.5298 10.9688 9.33594 12.1627 9.33594 13.6354C9.33594 15.1082 10.5298 16.3021 12.0026 16.3021Z'
        stroke='#28A745'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M12.9945 13.6685H11.0078'
        stroke='#28A745'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M12 12.6953V14.6886'
        stroke='#28A745'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M14 6.63558V11.8756C13.5133 11.3222 12.8 10.9689 12 10.9689C10.5267 10.9689 9.33333 12.1622 9.33333 13.6356C9.33333 14.1356 9.47333 14.6089 9.72 15.0089C9.86 15.2489 10.04 15.4622 10.2467 15.6356H5.33333C3 15.6356 2 14.3022 2 12.3022V6.63558C2 4.63558 3 3.30225 5.33333 3.30225H10.6667C13 3.30225 14 4.63558 14 6.63558Z'
        stroke='#28A745'
        stroke-miterlimit='10'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M7.99373 10.1019H7.99972'
        stroke='#28A745'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M5.5328 10.1019H5.53878'
        stroke='#28A745'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M5.5328 12.1019H5.53878'
        stroke='#28A745'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
    </svg>
  )
}
